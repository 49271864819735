import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

const Specification = (props: any) => {

    const [product, setProduct] = useState({ ...props.product });

    useEffect(() => {
        setProduct({ ...props.product });
    }, [props.product]);

    return <>
        <Container>
            <Col>
                <Row className="mt-2 specification">

                    {product.productDimension && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Dimension:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.productDimension}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.specifications && product.specifications.map((item: any) => (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title" >{item.attribute?.name}: </h6></Col>
                                <Col xs={6} lg={8}><h6>{item.value}</h6></Col>
                            </Row>

                        </Col>
                    ))}

                    {product.pieceOrCarton && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Pieces/Carton:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.pieceOrCarton}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.cartonLength && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Carton Length(L) cm:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.cartonLength}</h6></Col>
                            </Row>
                        </Col>
                    )}
                    {product.cartonWidth && (
                        <Col>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Carton Width(W) cm:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.cartonWidth}</h6></Col>
                            </Row>
                        </Col>
                    )}
                    {product.cartonHeight && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Carton Height(H) cm:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.cartonHeight}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.cartonCbm && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Carton CBM:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.cartonCbm}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.cartonWeight && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Carton Weight (Kgs):</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.cartonWeight}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.moq && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">MOQ:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.moq}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.manufactured && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Manufactured: </h6></Col>
                                <Col xs={6} lg={8}><h6>{product.manufactured}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.mouldFees && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Mould fees: </h6></Col>
                                <Col xs={6} lg={8}><h6>{product.mouldFees}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.industriallyCompostable && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Industrially compostable: </h6></Col>
                                <Col xs={6} lg={8}><h6>{product.industriallyCompostable}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.qualityProductCertified && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Quality product certified: </h6></Col>
                                <Col xs={6} lg={8}><h6>{product.qualityProductCertified}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.factoryFoodSafetyCertified && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Factory food safety certified: </h6></Col>
                                <Col xs={6} lg={8}><h6>{product.factoryFoodSafetyCertified}</h6></Col>
                            </Row>
                        </Col>
                    )}

                    {product.sku && (
                        <Col lg={6}>
                            <Row className="mt-2">
                                <Col xs={6} lg={4}><h6 className="title">Product Code:</h6></Col>
                                <Col xs={6} lg={8}><h6>{product.sku}</h6></Col>
                            </Row>
                        </Col>
                    )}

                </Row>
            </Col >


        </Container >
    </>
}
export default Specification;