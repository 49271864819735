import { Fragment, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

const Paginator = (props: {totalPages:number, onPageClick:Function, activePage:number}) => {

    const [totalPages, setTotalPages] = useState(props.totalPages);

    const loadNextSetData = (pageNo:number) => {
        props.onPageClick(pageNo);
    }

    const loadPreviousPage = () => {
        loadNextSetData(props.activePage - 1);
    }

    const loadNextPage = () => {
        loadNextSetData(props.activePage + 1);
    }

    const setupPaginationItems = (totalPages: number) => {
        const paginationItems:any[] = [];
        const totalPagination:number = totalPages > 4 ? 4 : totalPages;
        for(let i =0;i < totalPagination;i++) {
            paginationItems.push(<Pagination.Item key={i} onClick={() => loadNextSetData(i+1)}>{i + 1}</Pagination.Item>);
        }
        return paginationItems;
    }

    useEffect(() => {
      setTotalPages(props.totalPages);
    }, [props.totalPages]);

    return<>
        <Fragment>
            <Pagination>
                <Pagination.Prev onClick={loadPreviousPage}></Pagination.Prev>
                {
                  setupPaginationItems(totalPages)
                }
                <Pagination.Next onClick={loadNextPage}></Pagination.Next>
            </Pagination>
        </Fragment>
    </>
}
export default Paginator;