import { Col, Container, Image, Row } from "react-bootstrap"
import "./sustainability.css"
import { Fragment, useEffect } from "react"
import Header from "../../layouts/header/header"
import PageCarousel from "../home/components/page-carousel/page-carousel"
import SEO from "../../features/seo-helmet"


const Sustainability = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return <>
        <SEO title="Sustainability" description="Sustainability" keywords="Sustainability" name="" type="" />
        <Fragment>
            <Header />
            <section className="blog-detail-page section-b-space ratio2_3">
                <Container className="mt-5">
                    <Image className=" w-100 object-fit-cover" src="/assets/images/sustainability/Sustainability 1.jpeg"
                        height={400} />
                    <Row>
                        <p className="text-dark my-4">
                            At Biobox, we believe that responsible choices in packaging can make a significant impact on the environment. Our commitment to sustainability drives every aspect of our business, from material selection to manufacturing processes. Here's how our manufacturing facilities uphold our dedication to a greener, more sustainable future:
                        </p>
                    </Row>
                </Container>



                <Container className="content-page">
                    <Row className="my-3 ">
                        <Col lg={6} className="text-center sustanability-img">
                            <Image src="/assets/images/sustainability/Sustainabilityside-img.jpeg" alt=""
                                className="w-100 h-100 object-fit-cover" />
                        </Col>
                        <Col className="p-3 color-bg" lg={6}>

                            <Row className="page-title1">
                                <h2 className="title-inner1">Eco-Friendly Materials</h2>
                            </Row>
                            <Row>
                                <p className="text-dark ">
                                    We prioritize the use of eco-friendly materials such as recycled paper, bagasse, bamboo, and PLA (Polylactic Acid) in our packaging solutions. These materials are renewable, biodegradable, and have a lower environmental impact compared to traditional plastics and paper. We use eco-friendly inks and coatings that are low in volatile organic compounds (VOCs) and harmful chemicals
                                </p>
                            </Row>

                            <Row className="page-title1">
                                <h2 className="title-inner1">Waste Reduction</h2>
                            </Row>
                            <Row>
                                <p className="text-dark ">
                                    Our manufacturing processes are designed to minimize waste and resource consumption. We focus on efficiency, recycling, and reusing materials whenever possible to reduce our ecological footprint.
                                </p>
                            </Row>

                            <Row className="page-title1">
                                <h2 className="title-inner1">Biodegradability and Compostability</h2>
                            </Row>
                            <Row>
                                <p className="text-dark ">
                                    Many of our products are biodegradable and compostable, ensuring that they can naturally return to the environment without causing harm. We prioritize materials that break down into non-toxic components.
                                </p>
                            </Row>

                            <Row className="page-title1">
                                <h2 className="title-inner1">Energy Efficiency</h2>
                            </Row>
                            <Row>
                                <p className="text-dark ">
                                    We invest in energy-efficient technologies and practices to reduce our energy consumption and greenhouse gas emissions during production. Our factories are solar-powered, reducing reliance on non-renewable energy and contributing to a cleaner and more sustainable environment.
                                </p>
                            </Row>
                            <Row className="page-title1">
                                <h2 className="title-inner1">Consumer Education</h2>
                            </Row>
                            <Row>
                                <p className="text-dark ">
                                    We believe that informed consumers can make more sustainable choices. We educate our clients and partners about the environmental benefits of our packaging solutions, helping them make eco-conscious decisions.
                                </p>
                            </Row>


                        </Col>


                    </Row>

                </Container>
                <Container>

                    <Row>
                        <p className="text-dark">
                            At Biobox, we're not just providing packaging solutions; we're shaping a sustainable future. Together, we can make a positive impact on our planet, one package at a time. Choose Biobox for packaging that cares for the environment.
                        </p>
                    </Row>
                </Container>
            </section>
        </Fragment>
        <PageCarousel />
    </>
}

export default Sustainability