export const addCartAction = (product:any) => {
    return {
        type: 'ADD_CART_ITEM',
        payload: product
    }
}

export const removeCartItemAction = (productId:any) => {
    const payload:any = {};
    payload.productId = productId;
    return {
        type: 'REMOVE_CART_ITEM',
        payload: payload
    }
}

export const clearCartAction = () => {
    return {
        type: 'CLEAR_CART',
        payload: null
    }
}