import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CategoryApiService from "../../../data/api/services/category/category-api-service";
import { useEffect, useState } from "react";
import './categoty.css'
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCategoryListAction, saveCategoryListAction } from "../state/category-action";
import { Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Category = (props:{closeNavigation:Function}) => {


    const categoriesApiService: CategoryApiService = new CategoryApiService();

    const categorySelector = useSelector((state: any) => state);

    const [menus, setMenus] = useState([]);

    const navigate: NavigateFunction = useNavigate();

    const dispatch = useDispatch();

    const { categoryCode } = useParams();


    const getAllCategories = async () => {
        try {
            dispatch(clearCategoryListAction());
            const data: any = await categoriesApiService.getAllCategories();
            console.log("getCategories " , data);
            dispatch(saveCategoryListAction(data));
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }
    }

    const getCategoryByCode = async (categoryCode: any) => {
        if(!categoryCode) {
            return;
        }
        try {
            // reset();
            let data: any = await categoriesApiService.getCategorybyCode(categoryCode);
            dispatch(clearCategoryListAction());
            dispatch(saveCategoryListAction(data.id));
            console.log("getCategoryByCode", data);
        } catch (error) {
            console.log("getCategoryByCode Error");
            console.log(error);
        }
    }

    const goToProductSearch = (categoryCode: any) => {
        props.closeNavigation();
        const category = encodeURIComponent(categoryCode);
        navigate('/search-product?category=' + category, {});
    }

    const drawCategorySubMenu = (subMenus: any[], className: string) => {
        console.log(subMenus);
        if (subMenus && subMenus.length == 0) {
            return <></>
        }
      
        return <>
            <ul className={className}>
                {subMenus.map((subMenu: any) => (
                    <li className="dropdown">
                        {subMenu.subMenus
                            && subMenu.subMenus.length > 0
                            ? <a href="javascript:void(0)" className="py-2">
                                <span>{subMenu.name}</span><FontAwesomeIcon icon={faChevronRight} />
                                {
                                    drawCategorySubMenu(subMenu.subMenus, "submenu-dropdown-menu")
                                }
                            </a>
                            : <a href="javascript:void(0)" className="py-2" onClick={() => goToProductSearch(subMenu.categoryCode)}> <span>{subMenu.name}</span></a>
                        }
                    </li>
                ))
                }

            </ul>
        </>
    }

    const drawCategoryMenu = (menu: any): any => {
        if (menu.subMenus.length > 0) {
            return <>
                <Col>
                    <a href="javascript:void(0)" className='p-2' >
                        <span className='p-1 text-light'>{menu.name}</span><FontAwesomeIcon className="text-light" icon={faChevronDown} />
                    </a>
                </Col>
                {
                    drawCategorySubMenu(menu.subMenus, "dropdown-menu")
                }
            </>
        }
        return <>
            <Col>
                <a href="javascript:void(0)" onClick={() => goToProductSearch(menu.category_code)} className='p-2'>
                    <span className='p-1 text-light'>{menu.name}</span>
                </a>
            </Col>
        </>
    }

    useEffect(() => {
        getCategoryByCode(categoryCode);
        getAllCategories();
    }, []);

    return <>
        <Container fluid className="gray  category-header  d-sm-block d-lg-flex   py-3 justify-content-start ">
            <Container className='p-0'>
                <ul className="sm category d-sm-block d-lg-flex flex-wrap">
                    {categorySelector.categories.map((item: any, index: number) => (
                        <li key={index} className="dropdown">
                            {
                                drawCategoryMenu(item)
                            }
                        </li>
                    ))}
                </ul>
            </Container>
        </Container>
    </>
}
export default Category;


