
import { AxiosError } from "axios";
import HttpClientWrapper from "../../http-client-wrapper";

class CartApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    saveCart = async(payload:any):Promise<void> => {
        console.log("CartApiService saveCart() START");   
        await this.httpClientWrapper.post('/v1/carts',payload);
        console.log("CartApiService saveCart() END");     
    }

    getAllLocation = async():Promise<any[]> => {
        try{
            console.log("CartApiService getAllLocation() START");
            let data:any[] = await this.httpClientWrapper.get('/v1/locations' );
            console.log("CartApiService getAllLocation() END");     
            return(data);     
        }catch (error){
            throw error;
        }
    }
}
export default CartApiService;