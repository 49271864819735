import { Col, Container, Row, Image } from "react-bootstrap"
import './giving-back-the-nature.css';
import Header from "../../layouts/header/header";
import PageCarousel from "../home/components/page-carousel/page-carousel";
import { useEffect } from "react";
import SEO from "../../features/seo-helmet";

const GivingBackTheNature = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return <>
        <SEO title="Giving Back the Nature" description="Giving Back the Nature" keywords="Giving Back the Nature" name="Giving Back the Nature" type="Giving Back the Nature" />
        <Header />
        <section className="blog-detail-page section-b-space ratio2_3">
            <Container className="mt-5">
                <Image className=" w-100" height={400} src="/assets/images/give-back-nature/giving-back-to-nature.jpeg"
                />
                <Row>
                    <p className="text-dark my-4">
                        At the heart of our mission lies a profound dedication to giving back to nature. By aligning our business practices with nature-centric initiatives, we strive to create a harmonious balance between industrial innovation and environmental preservation, ensuring that our packaging solutions not only meet the highest standards of functionality and quality but also actively contribute to the restoration and protection of natural ecosystems.
                    </p>
                </Row>
            </Container>

            <Container className="content-page">
                <Row className="my-3 ">
                    <Col lg={6} className="text-center ">
                        <Image src="/assets/images/give-back-nature/save-plant.jpeg"
                            className="w-100 h-100 object-fit-cover" alt="" />
                    </Col>
                    <Col className="p-3 color-bg" lg={6}>
                        <Row>
                            <p className="text-dark ">
                                For every order placed with Biobox, we pledge to plant a tree. This initiative is a core part of our commitment to offsetting our environmental impact and contributing to global reforestation efforts. We collaborate with reforestation organizations, who specialize in large-scale tree planting and ecosystem restoration projects.
                            </p>
                        </Row>

                        <Row>
                            <p className="text-dark ">
                                Trees are powerful carbon sinks that absorb carbon dioxide from the atmosphere. By participating in reforestation, we actively contribute to carbon sequestration efforts, helping to combat climate change.
                            </p>
                        </Row>

                        <Row>
                            <p className="text-dark ">
                                We believe in raising awareness about the importance of reforestation. We educate our clients and partners about the positive impact of tree planting, encouraging them to join us in supporting these initiatives.
                            </p>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Choose Biobox to support packaging solutions that care for the environment and actively give back to nature through reforestation. Together, we can make a significant difference in protecting our planet for future generations.
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
        <PageCarousel />
    </>
}

export default GivingBackTheNature