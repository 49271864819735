import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap"

const QuantityWidget = (props:any) => {

    const [quantity, setQuantity] = useState();
    const [showError, setShowError] = useState(false);

    const handleOnQuantityValueChange = (event:any) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            if(showError) {
                setShowError(false);
            }
            setQuantity(event.target.value);
        }
    }

    const validateQuantity = (event:any) => {
        if(!quantity) {
            return;
        }
        if(quantity < parseInt(props.moq)) {
            setShowError(true);
            return;
        }
        props.onValueChanged(event.target.value);
    }

    return<>
        <Form>
            <Row className="mb-3">
                <Form.Group as={Col} className="d-flex" controlId="formGridQuantity">
                    <Form.Label className="fw-bold me-4">Quantity<span className="required text-danger">*</span></Form.Label>
                    <Form.Control className={` ${showError ? "error-input" : ""} w-50`} type="text" 
                    onChange={handleOnQuantityValueChange} value={quantity} 
                    onBlur={validateQuantity}/>
                </Form.Group>
                {
                    (showError? <Row className="error-message">
                        <Col>
                        Quantity should be greater than MOQ!
                        </Col>
                    </Row> : '')
                }
                
            </Row>
        </Form>
    </>
}
export default QuantityWidget;
