import HttpClientWrapper from "../../http-client-wrapper";


class NewsLetterApiService{

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    saveNewsLetter = async(payload:any):Promise<void> => {
        console.log("NewsLetterApiService saveNewsLetter() START");   
        await this.httpClientWrapper.post('/v1/newsLetters',payload);
        console.log("NewsLetterApiService saveNewsLetter() END");     
    }

}
export default NewsLetterApiService