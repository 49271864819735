import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface SEOProps {
    title: string,
    description: string,
    keywords: string,
    name: string,
    type: string
}
const SEO = ({title, description, keywords, name, type}: SEOProps) => {
    return <>
        <Helmet prioritizeSeoTags>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <meta name='image' content='/assets/images/icon/logo.png' />
            { /* End standard metadata tags */ }

            { /* Facebook tags */ }
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name='image' content='/assets/images/icon/logo.png' />
            { /* End Facebook tags */ }

            { /* Twitter tags */ }
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name='image' content='/assets/images/icon/logo.png' />
            { /* End Twitter tags */ }
        </Helmet>
    </>
}
export default SEO;