import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './layouts/footer/footer';
import AppRouter from './router/app-router';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};
  return (
    <>
        <ToastContainer enableMultiContainer containerId={'TR'} position={toast.POSITION.TOP_RIGHT} />
        <ToastContainer enableMultiContainer containerId={'BC'} position={toast.POSITION.BOTTOM_CENTER} />
        <HelmetProvider context={helmetContext}>
          <AppRouter />
          <Footer />
      </HelmetProvider>
    </>
  );
}

export default App;
