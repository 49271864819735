import { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { isNoSubstitutionTemplateLiteral } from "typescript";
import "./why-we-choose.css";

const data = [
    {
        title: 'Quick Links',
        subMenu: [
            {
                subName: 'Quality Control',
                path: '/quality-control'
            },
            {
                subName: 'Ethical Sourcing',
                path: '/ethical-sourcing',
            },
            {
                subName: 'Giving Back the Nature',
                path: 'giving-back-the-nature'
            },
            {
                subName: 'Sustainability',
                path: '/sustainability'
            },
            {
                subName: 'Materials Used',
                path: '/materials-used'
            },
           
            {
                subName: 'Composting',
                path: '/composting'
            },
           
            



        ]
    },
]
const WhyWeChoose = () => {
    const [openSubMenu, setOpenSubMenu] = useState(window.innerWidth >= 576);

    const toggleSubMenu = () => {
        setOpenSubMenu(!openSubMenu);
    };

    useEffect(() => {
        // Hide the submenu on smaller screens by default
        if (window.innerWidth < 576) {
            setOpenSubMenu(false);
        }
    }, []);

    return <>
        {data.map((item: any, idx: any) => (
            <Col key={idx}>
                <h6 className="text-uppercase mb-4 footer-title1"
                    onClick={toggleSubMenu}>
                  <b> {item.title} </b>
                </h6>
                {(window.innerWidth >= 576 || openSubMenu) && (
                    <Col>
                        {item.subMenu.map((sumItem: any, idx: any) => (
                            <p>
                                <a href={sumItem.path} className="text-reset">{sumItem.subName}</a>
                            </p>
                        ))}
                    </Col>
                )}
            </Col>
        ))}
    </>

}
export default WhyWeChoose