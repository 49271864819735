
import HttpClientWrapper from "../../http-client-wrapper";

class CategoryApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllCategories = async():Promise<any[]> => {
        try{
            let data:any[] = await this.httpClientWrapper.get('/v1/categories' );
            console.log("CategoriesApiService getAllCategories() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getCategoryById = async(id:any) => {
        try{
            let data:any[] = await this.httpClientWrapper.get('/v1/categories'+ id );
            console.log("CategoriesApiService getAllCategories() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getCategorybyName = async(name:any) => {
        try{
            let url: string = '/v1/categories/byCategory/name?categoryName=' + name;
            let data: any = await this.httpClientWrapper.get(url);
            console.log("CategoriesApiService getAllCategories() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getCategorybyCode = async (categoryCode: any) => {
        let data: any = await this.httpClientWrapper.get('v1/categories/getByCategoryCode/'+categoryCode);
        console.log("CategoriesApiService getCategorybyCode() response data" + JSON.stringify(data));
        return (data);
    }
}
export default CategoryApiService;