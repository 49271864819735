import HttpClientWrapper from "../../http-client-wrapper";

class ProductApiService {
    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllProduct = async (page: number = 1, pageSize: number = 10, category?: any, searchKeyword?: String) => {
        try {
            let url: string = '/v1/products?page=' + page + "&pageSize=" + pageSize;
            if (searchKeyword) {
                url = url + '&searchKeyword=' + searchKeyword;
            }
            if (category) {
                const encodedCategory = encodeURIComponent(category);
                url = url + '&category=' + encodedCategory;
            }
            let data: any = await this.httpClientWrapper.get(url);
            console.log("GetAllProduct() response data " + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getProductsByTag = async (tag: string, page: number = 1, pageSize: number = 10) => {
        try {
            let url: string = '/v1/products/' + tag + '?page=' + page + "&pageSize=" + pageSize;
            let data: any = await this.httpClientWrapper.get(url);
            console.log("getNewlyArrivedProducts() response data " + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getProductById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/products/' + id + "/details");
            console.log("GetProductById() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProductImgGalleryById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/products/' + id + "/productGallery");
            console.log("GetProductById() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getProductByVariants = async (productId: any, payload: any) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/products/' + productId + '/getByVariants', payload);
            console.log("GetProductById() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }

    getBaseProductById = async (id: any) => {
        let data: any = await this.httpClientWrapper.get('v1/baseProducts/' + id+"?storeFrondEnd=yes");
        console.log("BaseProductApiService getAllBaseProductById() response data" + JSON.stringify(data));
        return (data);
    }

    getAlternateProductById = async (id: any) => {
        let data: any = await this.httpClientWrapper.get('v1/products/' + id + "/alternateProducts");
        console.log("ProductApiService getAlternateProductById() response data" + JSON.stringify(data));
        return (data);
    }

    getRelatedProductById = async (id: any) => {
        let data: any = await this.httpClientWrapper.get('v1/products/' + id + "/relatedProducts");
        console.log("ProductApiService getRelatedProductById() response data" + JSON.stringify(data));
        return (data);
    }

    getProductByCode = async (productCode: any) => {
        let data: any = await this.httpClientWrapper.get('v1/products/getByProductCode/'+productCode);
        console.log("ProductApiService getProductByCode() response data" + JSON.stringify(data));
        return (data);
    }

}

export default ProductApiService;