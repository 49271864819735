import { useEffect, useState } from "react";
import { Carousel, Col, Container, Row, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import MenuState from "../../../../layouts/header/state/menu-state";
import './page-carousel.css';
import NewsletterSubscribe from "../../../../layouts/footer/components/newsletter/newsletter-subscribe";
import Categorystate from "../../../../layouts/header/state/category-state";
import CategoryApiService from "../../../../data/api/services/category/category-api-service";

const PageCarousel = () => {

    const productData1 = [

        {
            'code': "Quality Control",
            'image': "/assets/images/banner-images/quality-control-downbanner.jpeg",
            'tittle': "Dedicated to Delivering Impeccable Quality, Every Time",
            'path': "/quality-control"

        },

        {
            'code': "Ethical Sourcing",
            'image': "/assets/images/banner-images/ethical-sourcing-downbanner.jpeg",
            'tittle': "Empowering Communities, Sourcing with Integrity",
            'path': "/ethical-sourcing"

        },
        {
            'code': "Giving Back to the Nature",
            'image': "/assets/images/banner-images/giving-back-to-nature-downbanner.jpeg",
            'tittle': "Returning Nature's Favors, Renewing Our Commitment",
            'path': "/giving-back-the-nature"

        },
        {
            'code': "Sustainability",
            'image': "/assets/images/banner-images/sustainability-downbanner.jpeg",
            'tittle': "Championing Sustainability, Inspiring Change",
            'path': "/sustainability"

        },
        {
            'code': "Material Used",
            'image': "/assets/images/banner-images/materials-used-downbanner.jpeg",
            'tittle': "Harvesting the Future with Sustainably Sourced Raw Materials.",
            'path': "/materials-used"

        },
        {
            'code': "Composting",
            'image': "/assets/images/banner-images/composting-banner.jpeg",
            'tittle': "Sustainability Starts at Home, Join the Composting Movement.",
            'path': "/composting"
        },
    ]
    const [productData, setProductData] = useState([...productData1]);
    const [categoryData, setCategoryData] = useState<any[]>([]);
    const menuReducer = useSelector((state: MenuState) => state);
    const categorySelector = useSelector((state: Categorystate) => state);
    const categoriesApiService:CategoryApiService= new CategoryApiService();


    console.log('menuReducer', menuReducer.menuOption);

    const constructCarouselData = (data: any) => {
        setProductData(productData.filter(item => item.code !== data));
    }
    const getAllCategories = async () => {
        try {
            const data:any = await categoriesApiService.getAllCategories();
            setCategoryData(data);
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }
    }

    useEffect(() => {
        constructCarouselData(menuReducer.menuOption);
        getAllCategories();

    }, []);

    return <>
            <section className="test">
            <Container fluid className="home-slider">
                <Carousel interval={3000}>
                    {productData.map((productItem, index) => (
                        <Carousel.Item key={index}>
                            <Col className="home home12 text-start">
                                <Col className="bg-image">
                                    <Image src={productItem.image} className="img-fluid w-100  h-100 object-fit-cover" />
                                    <Row className="set">
                                        <Col  md={5}>
                                            <Col className="slider-contain ">
                                                <Col className="slider-content">
                                                    <h4 className="block-ellipsis-4line">{productItem.tittle}</h4>
                                                    <a className="btn btn-solid mt-4" href={productItem.path}>
                                                        Learn More
                                                    </a>
                                                </Col>
                                            </Col>
                                       
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </section>
        {/* <Container fluid className="p-0 mt-3">
            <Carousel interval={3000}>
                {productData.map((productItem, index) => (
                    <Carousel.Item key={index}>
                        <Col className="page-slide">
                            <Image src={productItem.image} className="image img-fluid" />
                            <Row className="set">
                                <Col md={{ span: 5}}>
                                    <Col className="slider-contain">
                                        <h4 className="block-ellipsis-4line">{productItem.tittle}</h4>
                                        <a className="btn btn-solid mt-4" href={productItem.path}>
                                            Learn More
                                        </a>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container> */}
        <NewsletterSubscribe />

    </>
}
export default PageCarousel