import Slider from "react-slick";
import {Col, Container, Image, Row} from "react-bootstrap"
import './sponser-icon.css'

const logos = [
    {
        logo: "/assets/images/sponser-icons/brcs-logo.png"
    },
    {
        logo: "/assets/images/sponser-icons/bsci.png"
    },
    {
        logo: "/assets/images/sponser-icons/compostable-logo.png"
    },
    {
        logo: "/assets/images/sponser-icons/fda-logo.png"
    },
    {
        logo: "/assets/images/sponser-icons/fsc-logo.png"
    },
    {
        logo: "/assets/images/sponser-icons/iso.png"
    },
    {
        logo: "/assets/images/sponser-icons/LFGBnaisol.png"
    }
]

const SponsersIcon = () => {

    const sliderSettings = {
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return <>
        <section className="p-3">
            <Container>
                <Row>
                    <Col md={12}>
                        <Col className="slide-6 no-arrow">
                            <Slider  {...sliderSettings} >
                                {logos.map((item: any, idx: any) => (
                                    <Col>
                                        <Col className="logo-block">
                                            <Image src={item.logo} alt="logo" />
                                        </Col>
                                    </Col>
                                ))}
                            </Slider>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </section>

    </>

}
export default SponsersIcon