import { faBars, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../header.css";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CategoryApiService from '../../../data/api/services/category/category-api-service';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import CartState from '../../../products/state/cart-state';
import { menuChangeAction } from '../state/menu-action';
import { Col, Container, Image } from 'react-bootstrap';

const menuData = [
    {
        name: 'Our Responsibility',
        path: '',
        subMenu: [
            {
                name: 'Quality Control',
                path: '/quality-control',
                subMenus: []
            },
            {
                name: 'Sustainability',
                path: '/sustainability',
                subMenus: []
            },
            {
                name: 'Material Used',
                path: '/materials-used',
                subMenus: []
            },
            {
                name: 'Ethical Sourcing',
                path: '/ethical-sourcing',
                subMenus: []
            },
            {
                name: 'Composting',
                path: '/composting',
                subMenus: []
            },
            {
                name: 'Giving Back to the Nature',
                path: '/giving-back-the-nature',
                subMenus: []
            }
        ]
    },
    {
        name: 'Why Chose Us?',
        path: '/why-choose-us',
        subMenu: []
    },

    {
        name: 'Contact Us',
        path: '/contact-us',
        subMenu: []
    }

]
const Menu = () => {

    const categoriesApiService: CategoryApiService = new CategoryApiService();

    const [menus, setMenus] = useState([...menuData]);

    const navigate: NavigateFunction = useNavigate();

    const cartReducer = useSelector((state: CartState) => state);
    const dispatch = useDispatch();

    const goToViewQuoteList = () => {
        navigate('/quotes');
    }


    const onChangeSubmenu = (path: string, menuName: any) => {
        dispatch(menuChangeAction(menuName));
        navigate(path);
    };

    const getAllCategories = async () => {
        try {
            let data = await categoriesApiService.getAllCategories();
            updateProductCategories(data);
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }

    }

    const updateProductCategories = (categories: any[]) => {
        menus.map((menu: any) => {
            if (menu.name == 'Products') {
                menu.subMenus = categories;
            }
        });
        setMenus([...menus]);
    }

    useEffect(() => {
        getAllCategories();
    }, []);

    return <>
        <Container className="menu-header d-sm-block d-lg-flex justify-content-between align-items-center ">
            <nav className=''>
                <ul className="sm pixelstrap d-sm-block d-lg-flex flex-wrap">
                    {menus.map((item: any, index) => (
                        <li key={index} className="dropdown">
                            <a href={item.path || undefined} className='p-2'>
                                <span className='p-1'>{item.name}</span>
                                {item.subMenu != 0 && (
                                    <FontAwesomeIcon icon={faChevronDown} />
                                )}
                            </a>
                            {item.subMenu.length != 0 && (
                                <ul className="dropdown-menu">
                                    {item.subMenu.map((subMenu: any) => (
                                        <li className="dropdown">
                                            <a className="p-2" onClick={() => onChangeSubmenu(subMenu.path, subMenu.name)}>
                                                <span>{subMenu.name}</span>
                                                {subMenu.subMenus.length != 0 && (
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                )}
                                            </a>
                                            {subMenu.subMenus.length != 0 && (
                                                <ul className="submenu-dropdown-menu">
                                                    {subMenu.subMenus.map((subMenuData: any) => (
                                                        <li className="dropdown">
                                                            <a href={subMenuData.path}>{subMenuData.name}</a>
                                                        </li>
                                                    ))}
                                                </ul>)}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
            <Col className="menu-right pull-right d-none d-lg-block">
                <Col>
                    <Col className="icon-nav mt-4">
                        <ul className='d-flex  justify-content-end p-0'>
                            <li className="onhover-div mobile-cart d-flex">
                                <div className="cart-qty-cls">{cartReducer.cartReducer.cartCount}</div>
                                <a href="javascript:void(0)" onClick={goToViewQuoteList}>
                                    <Col>
                                        <Image src="../assets/images/icon/add-to-quote-box.png" alt="box" className="media" width={25} />
                                    </Col>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Col>
            </Col>
        </Container>
    </>

}
export default Menu

