import { useEffect } from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Header from "../../layouts/header/header"
import PageCarousel from "../home/components/page-carousel/page-carousel"
import SEO from "../../features/seo-helmet"

const Composting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return <>
        <SEO title="Composting" description="Composting" keywords="Composting" name="Composting" type="Composting" />
        <Header />
        <section className="blog-detail-page section-b-space ratio2_3">
            <Container className="mt-5">
                <Image className="w-100" height={500} src="/assets/images/composting/Composting1.jpeg"
                />
                <Row>
                    <p className="text-dark my-4">
                        As an eco-packaging company, we strongly encourage the practice of composting as it aligns with our commitment to sustainability and environmental responsibility. Our products are designed to be compostable, allowing them to break down naturally without leaving behind harmful residues. By composting our packaging, you can contribute to the creation of nutrient-rich soil that supports the growth of healthy plants and contributes to a healthier ecosystem.
                    </p>
                </Row>
            </Container>

            <Container className="content-page">
                <Row className="my-3 ">
                    <Col lg={6} className="text-center">
                        <Image src="/assets/images/composting/Composting.jpeg"
                            className="w-100 h-100" height={700} alt="" />
                    </Col>
                    <Col className="p-3 color-bg" lg={6}>
                        <Row>
                            <p className="text-dark ">
                                Eco-packaging products can generally be composted using different composting methods,
                            </p>

                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1 ">Home composting</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Suitable for smaller scale composting, this method involves composting organic waste, including eco-packaging, in a controlled environment in your backyard.
                            </p>
                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Industrial composting</h2>
                        </Row >
                        <Row>
                            <p className="text-dark ">
                                Ideal for larger quantities, industrial composting facilities create controlled conditions for the decomposition of organic matter, including eco-friendly packaging, at a larger scale.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1 ">Vermicomposting</h2>
                        </Row >
                        <Row>
                            <p className="text-dark ">
                                This method involves the use of worms to break down organic materials, including eco-packaging, into nutrient-rich compost, which can be used as a natural fertilizer.
                            </p>
                        </Row>

                        <Row>
                            <p className="text-dark ">
                                The specific type of eco-packaging and its composition will determine the most suitable composting method, ensuring the efficient breakdown of materials and the production of high-quality compost.
                            </p>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <p className="text-dark ">
                        Embracing composting alongside the use of our eco-friendly packaging materials represents a powerful step towards reducing waste, minimizing pollution, and fostering a more sustainable and greener future for generations to come.
                    </p>
                </Row>

            </Container>
        </section>
        <PageCarousel />
    </>
}
export default Composting