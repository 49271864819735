import { combineReducers } from "redux";
import cartReducer from "../products/state/cart-reducer";
import categoryReducer from "../layouts/header/state/category-reducer";
import menuReducer from "../layouts/header/state/menu-reducer";
import productReducer from "../products/state/product-reducer";

const appReducers = combineReducers(
    {
        cartReducer: cartReducer,
        categories: categoryReducer,
        menuOption: menuReducer,
        productId: productReducer
    }
)

export default appReducers;