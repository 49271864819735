const categoryReducer = (state = [], action: {type:any, payload:any}) => {
    switch(action.type){
        case "SAVE_CATEGORY_LIST":
            return [...action.payload];
            case "CLEAR_CART":
            return [];
        default:
            return state;
    }
}
export default categoryReducer;


