import { Container, Row, Col } from "react-bootstrap";
import NewsletterSubscribe from "../../layouts/footer/components/newsletter/newsletter-subscribe";
import Header from "../../layouts/header/header";
import NewlyArrivedProduct from "./components/newly-arrived-product/newly-arrived-product";
import PaginationSpecialProduct from "./components/special-product/special-product";
import ProductBanner from "./components/product-banner/product-banner";
import ProductCarousel from "./components/product-carousel/product-carousel";
import "./home-container.css";
import Slider from "react-slick";
import WhoWearWhat from "./components/social-media-profile/social-media-profile";
import SponsersIcon from "./components/sponsers-icon/sponsers-icon";
import SocialMediaProfile from "./components/social-media-profile/social-media-profile";
import ProductCounter from "./components/product-counter/product-counter";
import AboutUs from "../about-us/about-us";
import TrendingProduct from "./components/trending-product/trending-product";
import SpecialProduct from "./components/special-product/special-product";
import Banner from "./components/banner/banner";
import PageCarousel from "./components/page-carousel/page-carousel";
import SEO from "../../features/seo-helmet";

const HomeContainer = () => {

    return <>
        <SEO title="Biobox Home" description="elevate your brand with earth-friendly packaging" 
        keywords="ecopacking, earth-friendly packing, sustainable future" name="Biobox Home" type="Biobox Home" />
        <Header />
        <ProductCarousel />
        <SpecialProduct />
        <NewlyArrivedProduct />
        <Banner />
        <TrendingProduct />
        <AboutUs />
        <SponsersIcon />
        <PageCarousel />
    </>
}
export default HomeContainer;