import { Col } from "react-bootstrap";
import { FaFacebookF, FaGooglePlusG, FaInstagram, FaRss, FaTwitter } from "react-icons/fa";
import { TfiFacebook, TfiGoogle, TfiInstagram, TfiRssAlt, TfiTwitterAlt } from "react-icons/tfi";

const icons = [
    {
        icon: <FaFacebookF />
    },
    {
        icon: <FaGooglePlusG />
    },
    {
        icon: <FaInstagram />
    },
    {
        icon: <FaTwitter />
    },
    {
        icon: <FaRss />
    }

]
const SocialMediaWidget = () => {

    return <>

        <Col className="footer-social">
            {icons.map((item: any, idx: any) => (
                <h6 className="fa fa-facebook-f mx-3 my-3">
                    {item.icon}
                </h6>
            ))}

        </Col>


    </>

}
export default SocialMediaWidget