import { useEffect, useState } from "react";
import { Carousel, Col, Modal, Row, Image, Container } from "react-bootstrap";
import './product-carousel.css'
import fakeData from '../../../../data/fake/product-carousel-data.json';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CategoryApiService from "../../../../data/api/services/category/category-api-service";
import Categorystate from "../../../../layouts/header/state/category-state";


const productData = [

    {
        'image': "/assets/images/banner-images/banner3.jpeg",
        'tittle': "Elevate Your Brand with Earth-Friendly Packaging ",
        'sub_tittle': "",
        'code': "Food Bowls",
    },

    {
        'image': "/assets/images/banner-images/banner5.jpeg",
        'tittle': "Where Innovation and style meets Conservation",
        'sub_tittle': "",
        'code': "Single Wall"

    },
    {
        'image': "/assets/images/banner-images/banner2.jpeg",
        'tittle': "Packaging that Cares for the Planet and Your Products",
        'sub_tittle': "",
        'code': "Clamshell Boxes"

    },
    {
        'image': "/assets/images/banner-images/sustainability.jpeg",
        'tittle': "Eco-Friendly Packaging Solutions for a Sustainable Future",
        'sub_tittle': "",
        'code': "Paper Pouches"

    },

]
const ProductCarousel = () => {

    const [data, setData] = useState([...fakeData]);

    const navigate: NavigateFunction = useNavigate();
    const [categoryData, setCategoryData] = useState<any[]>([]);
    const categorySelector = useSelector((state: Categorystate) => state);
    const categoriesApiService: CategoryApiService = new CategoryApiService();

    const onProductColorChange = (color: string, idx: number) => {
        console.log(color);
        data.map((product: any) => {
            if (product.gallery) {
                product.gallery.map((item: any) => {
                    if (item.color === color) {
                        product.defaultImage = item;
                        setData({ ...product });
                    }
                });
            }
            return product;
        });
    }

    const goToProductSearch = async (name: any) => {
        getCategoryId(name);
        const categoryId: any = await getCategoryId(name);
        if (categoryId) {
            navigate('/search-product?category=' + categoryId, {});
        } else {
            navigate('/search-product');
        }
    }

    const getCategoryId = async (name: any) => {
        try {
            let categoryId: any;
            const category: any = await categoriesApiService.getCategorybyName(name.trim());
            if (category) {
                categoryId = category.category_code;
            }
            return categoryId;
        } catch (error) {
            console.log(error);
        }
    }


    const getAllCategories = async () => {
        try {
            const data: any = await categoriesApiService.getAllCategories();
            setCategoryData(data);
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getAllCategories();

    }, []);
    return <>
        <section className="test">
            <Container fluid className="slide-1 home-slider">
                <Carousel interval={3000}>
                    {productData.map((productItem, index) => (
                        <Carousel.Item key={index}>
                            <Col className="home home12 text-start">
                                <Col className="bg-image">
                                    <Image src={productItem.image} className="img-fluid w-100  h-100 object-fit-cover" />
                                    <Row className="set">
                                        <Col md={5} >
                                            <Col className="slider-contain ">
                                                <Col className="slider-content">
                                                    <h2 className="headind-2 custom-font-size">{productItem.tittle}</h2>
                                                    {productItem.sub_tittle && <h3>{productItem.sub_tittle}</h3>}
                                                    <a
                                                        className="btn btn-solid"
                                                        href="javascript:void(0)"
                                                        onClick={() => goToProductSearch(productItem.code)}
                                                    >
                                                        Shop Now
                                                    </a>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </section>
    </>
}
export default ProductCarousel;