import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

export interface InlineSpinnerProps {
    variant?: 'primary' | 'secondary',
    align?: 'text-center',
    showSpinner: false | true
}

const InlineSpinner = (inlineSpinnerProps: InlineSpinnerProps) => {
    
    const [props, setProps] = useState({...inlineSpinnerProps});

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        setShowLoading(inlineSpinnerProps.showSpinner);
    }, [inlineSpinnerProps.showSpinner]);

    return <>
        {
            showLoading? <div className="text-center">
                <Spinner animation="border" variant={props.variant} />
            </div> : ''
        }
    </>
}
export default InlineSpinner;