import { Row, Col, Button, Form, Container } from "react-bootstrap";
import './newsletter-subscribe.css';
import { useState } from "react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { validate } from 'email-validator';
import NewsLetterApiService from "../../../../data/api/services/news-letter/news-letter-api-service";




const NewsletterSubscribe = () => {
    const [email, setEmail] = useState('');
    const newsLetterApiService: NewsLetterApiService = new NewsLetterApiService();


    const saveNewsLetter = async () => {
        try {
            const payload: any = {};
            payload.email = email;
            await newsLetterApiService.saveNewsLetter(payload);
            setEmail('');
            toast.success('  Subscription successful!', { containerId: 'TR', autoClose: 1000 });
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Inquiry Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        }
    };


    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    return <>

        <Container fluid className="light-layout">
            <Container>
                <section className="py-5 border-section border-top-0">
                    <Row>
                        <Col lg={6}>
                            <Col className="subscribe">
                                <h4>KNOW IT ALL FIRST!</h4>
                                <p>Never Miss Anything From Biobox By Signing Up To Our Newsletter.</p>
                            </Col>
                        </Col>
                        <Col lg={6} className="text-md-center">
                            <Row >
                                <Col lg={8} sm={6} md={8}>
                                    <Form>
                                        
                                        <Form.Group>
                                            <Form.Control type="email"
                                            onChange={(event) => setEmail(event.target.value)}
                                            placeholder="Enter your email" className="text-box p-3" />
                                            </Form.Group>

                                    </Form>
                                </Col>
                                <Col lg={4} sm={6} md={4}>
                                    <Button type="button" onClick={saveNewsLetter} className="btn btn-solid mt-1">subscribe</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Container>
    </>
}
export default NewsletterSubscribe;