import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { generatePath, useNavigate } from "react-router-dom";
import '../products.css'

const ProductCard = (item: any) => {

    const [product, setProduct] = useState(item.product);
    let navigate: any = useNavigate();

    const viewProductDetails = (productCode: string) => {
        if(productCode){
            productCode = encodeURIComponent(productCode);
          }
        productCode && navigate(generatePath("/product-details/:productCode", { productCode }));
    }

    useEffect(() => {

    }, []);

    return <>
        <Col md={6} lg={3} className="my-3">
            <Card className="product-box product-card shadow " >
                <Row>
                    <div className="zoom-image-container">
                    <Col className="image-size product-image-container">
                        <Card.Img variant="top" className="h-100 w-100" alt={product.productCode} title={product.productCode}
                            src={product.svgImgUrl} />
                    </Col>
                    </div>
                </Row>
                <Row className="m-0">
                    <Col className="product-detail text-center height">
                        <h4 title={product.name} className="title-block-ellipsis">{product.name}</h4>
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col className="product-detail text-center">
                        <h4 className="title-block-ellipsis-2line ps-2 lh-sm">{product.category[0]?.name}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center my-3">
                        <button onClick={() => viewProductDetails(product.productCode)} className="btn btn-solid">View Details</button>
                    </Col>
                </Row>
            </Card>
        </Col>

    </>
}
export default ProductCard;