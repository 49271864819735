import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./product-search-box.css";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useState } from 'react';
import ProductApiService from '../../../data/api/services/product/product-api-service';
import { generatePath, useNavigate } from 'react-router-dom';
import { Col, Container, InputGroup, Row } from 'react-bootstrap';

const ProductSearchBox = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<[]>([]);
    const navigate: any = useNavigate();
    const productApiSearch: ProductApiService = new ProductApiService();

    const handleSearch = async (query: string) => {
        setIsLoading(true);
        const paginationProduct: any = await productApiSearch.getAllProduct(1, 100,null, query);
        setOptions(paginationProduct.items);
        setIsLoading(false);
    };
    const onSelected = (selectedItem: any) => {
        let productCode = selectedItem?.[0]?.['productCode'];
        if(productCode){
            productCode = encodeURIComponent(productCode);
          }
        productCode && navigate(generatePath("/product-details/:productCode", { productCode }));
    }
    

    return <>
        <Container fluid>
            <Row>
                <Col >
                    <InputGroup>
                       
                        <AsyncTypeahead
                            filterBy={() => true}
                            id="product-search-box"
                            isLoading={isLoading}
                            labelKey="name"
                            minLength={3}
                            clearButton
                            onChange={onSelected}
                            onSearch={handleSearch}
                            options={options}
                            placeholder="Search by products"
                            renderMenuItemChildren={(option: any) => (
                                <>
                                    <span>{option.name}</span>
                                </>
                            )}
                        />
                         {/* <InputGroup.Text id="basic-addon1">
                         <FontAwesomeIcon icon={faMagnifyingGlass} />

                         </InputGroup.Text> */}
                    </InputGroup>

                </Col>

            </Row>
        </Container>

    </>
}
export default ProductSearchBox;