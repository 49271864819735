import React, { useState } from 'react';
import "./header.css";
import { faAngleLeft, faArrowLeftLong, faBars, faHeart, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './component/menu';
import { useSelector } from 'react-redux';
import CartState from '../../products/state/cart-state';
import SideNavbar from './component/side-navbar';
import { Button, Col, Container, Modal, Offcanvas, Row, Image } from 'react-bootstrap';
import Category from './component/category';
import Toolbar from './component/toolbar';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ProductSearchBox from '../../features/widgets/product-search/product-search-box';

const Header = () => {

    const navigate: NavigateFunction = useNavigate();

    const cartReducer = useSelector((state: CartState) => state);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const [category, setCategory] = useState(false);

    const closeCategory = () => setCategory(false);
    const openCategory = () => setCategory(true);

    const goToViewQuoteList = () => {
        navigate('/quotes');
    }

    const goToHome = () => {
        navigate('/');
    }


    return (
        <>
            <header>
                <Container fluid className="position-fixed  z-2 bg-light">
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col className="border-section border-top-0">
                                    <Col sm={6} md={10} className="offset-sm-1">
                                        <Row className="align-items-center">
                                            <Col xs={2} className="text-center d-lg-none d-block">
                                                <Col className="bar-style  d-lg-none d-block">
                                                    <FontAwesomeIcon className='fa-2xl' onClick={handleShow}
                                                        icon={faBars} />
                                                    <Offcanvas show={show} onHide={handleClose}>
                                                        <Offcanvas.Header className='close-sidebar'>
                                                            <Offcanvas.Title onClick={handleClose}> <FontAwesomeIcon icon={faAngleLeft} /> Back To NavBar</Offcanvas.Title>
                                                        </Offcanvas.Header>
                                                        <Offcanvas.Body>
                                                            <ProductSearchBox />
                                                            <Menu />
                                                            <Category closeNavigation={handleClose} />

                                                        </Offcanvas.Body>
                                                    </Offcanvas>
                                                </Col>
                                            </Col>
                                            <Col xs={10} lg={12} className="fix d-flex align-items-center justify-content-between">
                                                <Col lg={3} md={4}>
                                                    <a href='/' onClick={goToHome}>
                                                        <Image
                                                            src="../assets/images/icon/logo.png"
                                                            // width="300"
                                                            width="150"
                                                            className="img-fluid lazyload"
                                                            alt="" />
                                                    </a>
                                                </Col>
                                                <Col md={8} lg={3} className='d-none d-md-block'>
                                                    <ProductSearchBox />
                                                </Col>
                                                <Col md={6} lg={7}>
                                                    <Toolbar />
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </header>

            <Col className='d-none d-lg-block'>
                <Category closeNavigation={handleClose} />
            </Col>


        </>

    );
};

export default Header;