import { Button, Col, Container, Form, Row, Image, Card } from "react-bootstrap";
import Header from "../../layouts/header/header";
import { Fragment, useEffect, useState } from "react";
import ProductApiService from "../../data/api/services/product/product-api-service";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import Paginator from "../../common/paginator/paginator";
import './search-product.css'
import ProductCard from "../../products/component/product-card";
import InlineSpinner from "../../common/spinner/inline-spinner";
import CategoryBreadCrumb from "../../layouts/category-bread-crumb/category-bread-crumb";
import { Helmet } from "react-helmet";
import SEO from "../../features/seo-helmet";

const SearchProduct = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get("category") || '';
    const productApiService: ProductApiService = new ProductApiService();
    const [productData, setProductData] = useState<any>([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [totalPages, setTotalpages] = useState(0);
    const [activePage, setActivePage] = useState(0);

    const getProducts = async (page: number) => {
        try {
            reset();
            setShowSpinner(true);
            let result = await productApiService.getAllProduct(page, 40, category);
            console.log("getPackageData", result);
            setProductData([...result.items]);
            paginationSetup(result.meta);
            setShowSpinner(false);
        } catch (error) {
            setShowSpinner(false);
            console.log("Product Error");
            console.log(error);
        }
    }

    const paginationSetup = (meta: any) => {
        setTotalpages(meta.totalPages);
        setActivePage(meta.currentPage);
    }

    const onPageClickCallback = (pageNo: number) => {
        getProducts(pageNo);
    }

    const reset = () => {
        setProductData([]);
    }

    useEffect(() => {
        getProducts(1);
    }, [category]);

    return <>
        <Fragment>
            <SEO title={category} description={category} keywords={category} name={category} type={category} />
            <Header />
            <section>
                
                <Container className="my-5">
                    <Row>
                        <CategoryBreadCrumb categoryId={category} />
                    </Row>
                    <Row>
                    <Col>
                            {<InlineSpinner variant="secondary" showSpinner={showSpinner} />}
                            {
                                productData.length > 0 ?
                                    <Row className="no-slider row">
                                        {productData.map((item: any, idx: any) => (
                                            <ProductCard product={item} />
                                        ))}

                                    </Row>

                                    : ''
                            }
                        </Col>
                    </Row>

                </Container>

                <Paginator totalPages={totalPages} onPageClick={onPageClickCallback} activePage={activePage}></Paginator>
            </section>


        </Fragment>
    </>
}
export default SearchProduct;