import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap"
import CountUp from 'react-countup';

const ProductCounter = () => {
    
    return <>
        <Container>
            <Row className="ms-2">
                <Row className="counter">
                    <Col md={4} className="text-center my-2">
                        <Col className="info m-3 h-100 px-2 py-3">
                            <h3 ><CountUp end={10000000} duration={5} enableScrollSpy/>+</h3>
                            <p>Plastic Products Replaced</p>
                        </Col>
                    </Col>
                    <Col md={4} className="text-center my-2">
                        <Col className="info m-3 h-100 px-2 py-3">
                            <h3><CountUp end={6} duration={5} enableScrollSpy />+</h3>
                            <p>Locations Across the Globe</p>
                        </Col>
                    </Col>
                    <Col md={4} className="text-center my-2">
                        <Col className="info m-3 h-100 px-2 py-3">
                            <h3><CountUp end={1000} duration={5} enableScrollSpy />+</h3>
                            <p>Happy Customers</p>
                        </Col>
                    </Col>
                </Row>

            </Row>
        </Container>
    </>
}

export default ProductCounter