import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CategoryBreadCrumb = (props:any) => {
    
    const categorySelector = useSelector((state: any) => state);
    const [categories, setCategories] = useState<any>([]);
    const navigate:NavigateFunction = useNavigate();

    const constructCategoryBreadCrumb = () => {
        const categories:any = categorySelector.categories;
        if(!categories) {
            return;
        }
        const lCategories:any[] = [];
        flatCategory(categories, lCategories);
        let selectedCategory:any;
        for(let i =0;i < lCategories.length;i++) {
            if(lCategories[i].categoryCode  == props.categoryId) {
                selectedCategory = lCategories[i];
                break;
            }
        }
        const results:any[] = [];
        if(selectedCategory.parent) {
            getAllParentCategory(selectedCategory.parent.id, lCategories, results);
        }
        results.push(selectedCategory);
        //console.log(results);
        setCategories([...results]);
    }

    const getAllParentCategory = (parentId:any, lCategories:any[], results:any[]):void => {
        for(let i =0;i < lCategories.length;i++) {
            if(lCategories[i].id == parentId) {
                results.unshift(lCategories[i]);
                if(lCategories[i].parent) {
                    getAllParentCategory(lCategories[i].parent.id, lCategories, results);
                }
            }
        }
    }

    const flatCategory = (categories:any[], lCategories:any[]) => {
        for(let i =0;i < categories.length;i++) {
            if(categories[i].category_code){
                categories[i]['categoryCode'] = categories[i].category_code;
            }
            lCategories.push(categories[i]);
            if(categories[i].subMenus.length > 0) {
                flatCategory(categories[i].subMenus,lCategories);
            }
        }
    }

    const handleCategoryChange = (categoryCode:string) => {
        const category = encodeURIComponent(categoryCode);
        navigate('/search-product?category='+ category, {});
    }

    useEffect(() => {
        if(props.categoryId) {
            constructCategoryBreadCrumb();
        }
        return () => { setCategories([]) };
    }, [props.categoryId]);

    return <>
    <Container>
        <Row>
             <nav className="theme-breadcrumb">
                            <ol className="breadcrumb">
                                {categories && categories.map((category:any, index:any) => (
                                    <li key={index} className="fw-bold green-colour">
                                        {index === categories.length - 1 ? (
                                             <label style={{ cursor: 'pointer' }} onClick={() => handleCategoryChange(category.categoryCode)}>&nbsp;{category.name}</label>
                                        ) : (
                                            <label>{category.name} / </label>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </nav>
        </Row>
    </Container>
    </>

}
export default CategoryBreadCrumb;