const productReducer = (state = [], action: {type:any, payload:any}) => {
    switch(action.type){
        case "SET_PRODUCT_ID":
            return action.payload;
        case "CLEAR_PRODUCT_ID":
            return null;
        default:
            return state;
    }
}
export default productReducer;