const cartInitialstate:any = {
    cart: [],
    cartCount: 0
}
const cartReducer = (state = cartInitialstate, action: {type:any, payload:any}) => {
    switch(action.type) {
        case "ADD_CART_ITEM":
            if(state.cart.length > 0) {
                const itemInCart = state.cart.find((item:any) => item.product.productId === action.payload.product.productId);
                if(!itemInCart) {
                    state.cartCount = state.cartCount + 1;
                    state.cart.push(action.payload);
                } else {
                    alert("Item already added to cart");
                }
            } else {
                state.cartCount = state.cartCount + 1;
                state.cart.push(action.payload);
            }
            return {...state, cartCount: state.cartCount, cart: state.cart};
        case "REMOVE_CART_ITEM":
            let productIdx = -1;
            for(let i =0;i < state.cart.length;i++) {
                if(state.cart[i].product.productId == action.payload.productId) {
                    productIdx = i;
                    break;
                }
            }
            if(productIdx != -1) {
                state.cartCount = state.cartCount -1;
                state.cart.splice(productIdx, 1);
            }
            return {...state, cartCount: state.cartCount, cart: state.cart};
        case "CLEAR_CART":
            return {...state, cartCount: 0, cart: []};
        default:
            return state;
    }
}
export default cartReducer;