import { Breadcrumb, Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import { FaUser, FaMapMarkerAlt, FaGlobeAmericas, FaDesktop, FaEnvelope, FaMobileAlt } from "react-icons/fa"
import "./contact-us.css"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { FaLocationDot } from "react-icons/fa6"
import { faFax, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Header from "../header/header"
import CartApiService from "../../data/api/services/cart/cart-api-service"
import axios, { AxiosError } from "axios"
import validator from 'email-validator';
import ContactUsApiService from "../../data/api/services/contact-us/contact-us-api-service"
import { validate } from 'email-validator';
import SEO from "../../features/seo-helmet"



const ContactDetails = [
    {
        location: 'AUSTRALIA & NEW ZEALAND',
        // office: 'Sales Headoffice',
        address: '100 George Street, Parramatta',
        address1: 'NSW 2150, Australia',
        website: 'sales@biobox.au',
        phone: '+61 493 624 746'
    },
    // {
    //     location: 'CHINA & EAST ASIA',
    //     office: 'Manufacturing Headoffice',
    //     address: 'Kelly Bai',
    //     website: 'kelly@ecopackagingdirect.com.cn',
    //     phone: '+861 7821 112 229'
    // },
    {
        location: 'MIDDLE EAST & AFRICA ',
        // location: 'MIDDLE EAST',
        office: 'Covalent General Trading LLC',
        address: 'Mohammed Arshad Nissar',
        website: 'arshad@biobox.au',
        phone: '+971 56682 0005'
    },
    // {
    //     location: 'NORTH AMERICA',
    //     address: 'Vagelis Karatzas',
    //     website: 'vagelis@ecopackagingdirect.world',
    //     phone: '+1 727 550 7874'
    // },
    {
        location: 'INDIA & SOUTHEAST ASIA',
        address: 'Ashok Jayaram',
        website: 'ashok@biobox.au',
        phone: '+91 9113573720'
    },
    // {
    //     location: 'SOUTH AMERICA',
    //     address: 'Ignacio',
    //     website: 'espinosaignacio@ecopackagingdirect.world',
    //     phone: '+549 2236 816 046'
    // },
    // {
    //     location: 'TURKEY',
    //     address: '',
    //     website: '',
    //     phone: '+90 5336 644 480'
    // },
    // {
    //     location: 'GREECE & CYPRUS',
    //     address: '',
    //     website: '',
    //     phone: '+30 697 8544 319'
    // },
]

const ContactUs = () => {
    const contactUsApiService: ContactUsApiService = new ContactUsApiService();
    const cartApiService: CartApiService = new CartApiService();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
    const [location, setLocation] = useState('');
    const [locationData, setLocationData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate: NavigateFunction = useNavigate();

    const saveContactUs = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        const payload: any = {};
        payload.firstName = firstName;
        payload.lastName = lastName;
        payload.email = email;
        payload.contactNumber = contactNumber;
        payload.companyName = companyName;
        payload.message = message;
        payload.locationId = location;

        try {
            if (!firstName) {
                toast.error("First Name cannot be empty!");
            }
            if (!email) {
                toast.error("Email cannot be empty!");
            }
            if (!validate(email)) {
                toast.error("Incorrect email format!", { containerId: 'TR' });
                return;
            }

            await contactUsApiService.saveContactUs(payload);
            toast.success('Request ContactUs submitted Successfully!', { containerId: 'TR', autoClose: 1000 });
            navigate('/');
        } catch (error: any) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Inquiry Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleContactNumberChange = (event: any) => {
        const inputValue = event.target.value;
        const cleanedValue = inputValue.replace(/\D/g, '').slice(0, 20);
        setContactNumber(cleanedValue);
    };

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const getAllLocations = async () => {
        try {
            const data: any = await cartApiService.getAllLocation();
            setLocationData(data);
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getAllLocations();
    }, []);

    return <>
        <SEO title="Conatct Us" description="Conatct Us" keywords="Conatct Us" name="Conatct Us" type="Conatct Us" />
        <Header />
        <section className="contact-page section-b-space">
            <Container>
                <Row className="row section-b-space">

                    <Col>
                        <Col className="contact-right">
                            <ul className="p-0">
                                <Row>
                                    {ContactDetails.map((item: any, idx: any) => (
                                        <Col md={6}>
                                            <li className="my-4" key={idx}>
                                                <Col className="contact-icon">
                                                    <FontAwesomeIcon icon={faLocationDot} className="text-danger m-2" />
                                                    <h6>Location</h6>
                                                </Col>
                                                <Col className="media-body">
                                                    <p><b>{item.location}</b></p>
                                                    <p className="contact-office"><b>{item.office}</b></p>
                                                    <p>{item.address}</p>
                                                    {item.address1 && <p>{item.address1}</p>}
                                                    <p>{item.website}</p>
                                                    <p className="d-flex">
                                                        <FontAwesomeIcon icon={faPhone} className="text-danger m-2" />
                                                        <p>{item.phone}</p>
                                                    </p>
                                                </Col>
                                            </li>
                                        </Col>

                                    ))}
                                </Row>
                            </ul>
                        </Col>
                    </Col>

                </Row>
                <Row className="page-title1">
                    <h2 className="title-inner1">Contact Us</h2>
                </Row>
                <Card className=" shadow p-4">
                    <Row>
                        <Col>
                            <Form className="theme-form" onSubmit={saveContactUs}>
                                <Row className="form-row">
                                    <Col md={6}>
                                        <Form.Label htmlFor="name">First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            placeholder="Enter Your Name"
                                            value={firstName}
                                            onChange={event => setFirstName(event.target.value)}
                                            required
                                            style={{ fontSize: '16px' }}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Form.Label htmlFor="last-name">Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="last-name"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={event => setLastName(event.target.value)}
                                            required
                                            style={{ fontSize: '16px' }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="email">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            id="email"
                                            placeholder="Email"
                                            onChange={(event) => setEmail(event.target.value)}
                                            required                                            // isInvalid={email && !validator.validate(email)}

                                            style={{ fontSize: '16px' }}

                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="phone-number">Phone Number</Form.Label>
                                        {/* <Form.Control
                                            type="number"
                                            id="phone-number"
                                            placeholder="Enter Your Number"
                                            value={contactNumber}
                                            onChange={event => handleContactNumberChange}
                                        
                                            style={{ fontSize: '16px' }}

                                        /> */}
                                        <Form.Group className="my-2">
                                            <Form.Control
                                                type="number"
                                                value={contactNumber}
                                                placeholder="Enter Your Number"
                                                onChange={handleContactNumberChange}
                                                maxLength={10}
                                                style={{ fontSize: '16px' }}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col md={6}>
                                        <Form.Label htmlFor="dropdown">Select Region</Form.Label>
                                        <Form.Control as="select" id="dropdown" required onChange={event => setLocation(event.target.value)}>
                                            <option value="">Select Region</option>
                                            {locationData.map((item: any) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Label htmlFor="company-name">Company Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="company-name"
                                            placeholder="Enter Company Name"
                                            value={companyName}
                                            onChange={event => setCompanyName(event.target.value)}
                                            required
                                            style={{ fontSize: '16px' }}

                                        />
                                    </Col>

                                    <Col md={12}>
                                        <Form.Label htmlFor="message">Write Your Message</Form.Label>
                                        <textarea
                                            className="form-control"
                                            placeholder="Write Your Message"
                                            id="message"
                                            rows={6}
                                            value={message}
                                            onChange={event => setMessage(event.target.value)}
                                            style={{ fontSize: '16px' }}
                                        ></textarea>
                                    </Col>

                                    <Col md={12}>
                                        <Button className="btn btn-solid" type="submit" >Send Your Message</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </section>
    </>
}

export default ContactUs