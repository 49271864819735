import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../../layouts/header/header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { clearCartAction, removeCartItemAction } from "../../../products/state/cart-action";
import { Button, Card, Col, Container, Form, FormLabel, Image, InputGroup, Row, Table } from "react-bootstrap";
import { NavigateFunction, useNavigate } from "react-router-dom";
import CartApiService from "../../../data/api/services/cart/cart-api-service";
import { toast } from "react-toastify";
import './view-quote.css'
import validator from 'email-validator';
import axios, { AxiosError } from "axios";
import { Helmet } from "react-helmet";
import SEO from "../../../features/seo-helmet";


// const locationData = [
//     {
//         country: 'Australia'
//     },
//     {
//         country: 'New Zealand'
//     },
//     {
//         country: 'China'
//     },
//     {
//         country: 'East Asia'
//     },
//     {
//         country: 'Middle East and Africa'
//     },
//     {
//         country: 'India'
//     },
//     {
//         country: 'South East Asia'
//     },
//     {
//         country: 'North America'
//     },
//     {
//         country: 'South America'
//     },
//     {
//         country: 'Europe'
//     },
// ]
const ViewQuoteList = () => {

    const appReducer = useSelector((state: any) => state);
    const cartApiService: CartApiService = new CartApiService();
    const [firstName, setFirstName] = useState<any | null>(null);
    const [lastName, setLastName] = useState<any | null>(null);
    const [email, setEmail] = useState<any | null>(null);
    const [contactNumber, setContactNumber] = useState<any | null>(null);
    const [companyName, setCompanyName] = useState<any | null>(null);
    const [message, setMessage] = useState<any | null>(null);
    const [location, setLocation] = useState<any | null>(null);
    const [locationData, setLocationData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [quantity, setQuantity] = useState(
        appReducer.cartReducer.cart.length > 0 ? appReducer.cartReducer.cart[0].qty : 0
    );
    const [cartData, setCartData] = useState<any[]>([]);
    const handleQuantityChange = (event: any,idx:any) => {
        const inputValue = event.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            cartData[idx].qty = inputValue
            setCartData([...cartData]);
        }
    };



    const handleContactNumberChange = (event: any) => {
        const inputValue = event.target.value;
        const cleanedValue = inputValue.replace(/\D/g, '').slice(0, 20);
        setContactNumber(cleanedValue);
    };

    const dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    const removeCartitem = (product: any) => {
        dispatch(removeCartItemAction(product.productId));
    }

    const goToHome = () => {
        navigate('/');
    }

    const onProceedListener = () => {
        navigate('/product-checkout');
    }
    const onRequestQuoteListener = async (event: any) => {
        event.preventDefault();
        setIsLoading(true); // Set loading to true before the operation
      
        const payload: any = {};
        payload.products = getAllCartProducts();
        payload.firstName = firstName;  
        payload.lastName = lastName;
        payload.email = email;
        payload.contactNumber = contactNumber;
        payload.companyName = companyName;
        payload.message = message;
        payload.location = location;
      
        try {
          await cartApiService.saveCart(payload);
          dispatch(clearCartAction());
          toast.success('Request for Quote submitted successfully!', { containerId: 'TR', autoClose: 1000 });
          navigate('/');
        } catch (error: any) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Inquiry Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        } finally {
          setIsLoading(false); // Set loading back to false after the operation
        }
      };
        const handleAxiosError = (error: AxiosError) => {
            if (error.response) {
                const errorMessage = (error.response.data as { message: string }).message;
                toast.error(errorMessage, { containerId: 'TR' });
            } else if (error.request) {
                toast.error('No response from server', { containerId: 'TR' });
            } else {
                toast.error(error.message, { containerId: 'TR' });
            }
        }
            

    const getAllCartProducts = () => {
        const products: any[] = [];
        const cartProducts = appReducer.cartReducer.cart;
        // if(!cartProducts || cartProducts.length == 0){
        //     throw new Error('You have to select atleast one product to send Inquiry!');
                 
        // }
        for (let i = 0; i < cartProducts.length; i++) {
            const product: any = {};
            product.productId = cartProducts[i].product.productId;
            product.qty = cartProducts[i].qty;
            products.push(product);
        }
        return products;
    }

    const getAllLocations = async () => {
        try {
            const data: any = await cartApiService.getAllLocation();
            setLocationData(data);
        } catch (error) {
            console.log("getCategories Error");
            console.log(error);
        }
    }

    useEffect(() => {
        console.log(appReducer);
        setCartData( appReducer.cartReducer.cart.length > 0 ? appReducer.cartReducer.cart : 0)
        getAllLocations();
    }, []);

    return <>
        <SEO title="Quote" description="Quote" keywords="Quote" name="Quote" type="Quote" />
        <Header />
        <section className="section-b-space">
            <Container className="mt-5">
                <Card className="h-100 shadow p-4">
                    <Row>
                        <Col md={6} className="px-5">

                            <Row className="d-flex flex-column justify-content-between">
                                <Col>
                                    <Col className="page-title1 my-3">
                                        <h2 className="title-inner1">Quote Box</h2>


                                    </Col>

                                    <Table responsive className="cart-table mt-3">
                                        <thead>
                                            <tr className="text-center">
                                                <th scope="col">Name</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col"> </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cartData && cartData.map((item: any, idx: any) => (
                                                    <tr>

                                                        <td className="">
                                                            <Row>
                                                                <Col sm={4}>
                                                                <Image src={item.product.svgImgUrl} className="img-fluid img-thumbnail w-100  h-100 object-fit-fill" alt={item.product.name} />

                                                                    {/* <Image src={item.product.imageURL}
                                                                        className="img-fluid img-thumbnail h-100 object-fit-cover" alt={item.product.name} height={100} width={100} /> */}
                                                                </Col>
                                                                <Col>
                                                                    <FormLabel className="my-2">{item.product.name}</FormLabel>
                                                                    <br />
                                                                    <FormLabel className="my-2"><b>MOQ :</b> {item.product.moq}</FormLabel>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                        <td >
                                                            <Col >
                                                                {/* <InputGroup className="">
                                                                    <Form.Control
                                                                        type="number" name="quantity" className="form-control input-number w-75 my-3" defaultValue={item.qty}
                                                                    />
                                                                </InputGroup> */}
                                                                <Form.Group className="">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="quantity"
                                                                        className="w-100 my-3"
                                                                        value={item.qty}
                                                                        onChange={(e) => handleQuantityChange(e,idx)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </td>
                                                        <td className="text-center">
                                                            <Col className="my-4">
                                                                <a href="javascript:void(0)" onClick={() => removeCartitem(item.product)} className="icon"><FontAwesomeIcon icon={faXmark} /></a>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>

                                    </Table>

                                    <Button className="btn btn-solid" type="submit" onClick={goToHome}>Shop More</Button>

                                </Col>
                            </Row>
                        </Col>

                        <Col md={6} className="border-left px-5">
                            <Col className="page-title1 my-3">
                                <h2 className="title-inner1">Request For Quote Form</h2>
                            </Col>

                            <Form noValidate onSubmit={onRequestQuoteListener} className="mt-3">
                                <Form.Group className="my-2">
                                    <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text"
                                        value={firstName} onChange={event => setFirstName(event.target.value)} />
                                </Form.Group>
                                <Form.Group className="my-2">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" value={lastName} onChange={event => setLastName(event.target.value)} />
                                </Form.Group>
                                {/* <Form.Group className="my-2">
                                    <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="email" value={email} onChange={event => setEmail(event.target.value)} />
                                </Form.Group> */}

                                <Form.Group className="my-2">
                                    <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        isInvalid={email && !validator.validate(email)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email address.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Label>Contact Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={contactNumber}
                                        onChange={handleContactNumberChange}
                                        maxLength={20}
                                    />
                                </Form.Group>


                                <Form.Group className="my-2">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control type="text" value={companyName} onChange={event => setCompanyName(event.target.value)} />
                                </Form.Group>
                                <Form.Group className="my-2">
                                    <Form.Label>Region <span className="text-danger">*</span></Form.Label>
                                    <Form.Select aria-label="Select Location" value={location} onChange={event => setLocation(event.target.value)}>
                                        <option value="">Select</option>
                                        {locationData.map((item: any, idx: any) => (
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="my-2" controlId="messageCtrl">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={event => setMessage(event.target.value)} />
                                </Form.Group>
                                <Button
                                    className="btn btn-solid"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Submitting...' : 'Send Inquiry'}
                                </Button>

                                {/* <Button className="btn btn-solid" type="submit">Send Inquiry</Button> */}
                            </Form>
                        </Col>


                    </Row>
                </Card>
            </Container>
        </section>
    </>
}
export default ViewQuoteList;