import { Col, Container, Row, Image } from "react-bootstrap";
import './materials-used.css';
import Header from "../../layouts/header/header";
import PageCarousel from "../home/components/page-carousel/page-carousel";
import { useEffect } from "react";
import SEO from "../../features/seo-helmet";

const MaterialsUsed = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return <>
        <SEO title="Material Used" description="Material Used"
            keywords="Material Used" name="Material Used" type="Material Used" />
        <Header />
        <section className="blog-detail-page section-b-space ratio2_3">
            <Container className="mt-5">
                <Image className=" w-100 object-fit-cover" src="/assets/images/materials-used/materials-used.jpeg"

                    height={400} />
                <Row className="mt-3">
                    <p>
                        The production of eco-packaging products relies on a wide range of raw materials carefully chosen to minimize harm to the environment. These materials contribute to the development of packaging solutions that not only serve their intended purpose but also align with the principles of responsible environmental stewardship.
                    </p>
                </Row>
            </Container>

            <Container className="content-page">
                <Row className="my-3 ">
                    <Col lg={6} className="text-center">
                        <Image src="/assets/images/materials-used/tree.jpeg"
                            className="w-100 h-100" alt="" />
                    </Col>
                    <Col className="p-3 color-bg" lg={6}>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Recycled Paper and Cardboard</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Recycled paper and cardboard are at the heart of many of our packaging solutions. These materials are sourced from post-consumer and post-industrial waste, reducing the need for new resources and lowering carbon emissions.
                            </p>

                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Bamboo</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Bamboo is one of the fastest-growing plants on Earth, which makes it an exceptionally sustainable resource. Bamboo's natural appearance and texture can lend an elegant, strong and eco-conscious aesthetic to packaging, making it a preferred choice for brands with sustainability goals
                            </p>

                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1 ">Bagasse</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Bagasse is a fibrous material left behind after sugarcane is crushed to extract its juice. It is considered an agricultural waste product, making its use in packaging environmentally responsible. Bagasse can be processed into various forms suitable for packaging, including bagasse pulp for moulded packaging products, bagasse paper for boxes and bags, and bagasse-based composite materials.
                            </p>
                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Polylactic Acid (PLA)</h2>
                        </Row >
                        <Row>
                            <p className="text-dark ">
                                PLA is derived from renewable resources like corn starch or sugarcane. It is often used for single-use items like food containers, cutlery, and packaging. PLA is compostable under industrial composting conditions.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1 ">Starch-Based Plastics</h2>
                        </Row >
                        <Row>
                            <p className="text-dark ">
                                These plastics are made from starch, usually from corn or potatoes. They are biodegradable and are often used for items like disposable bags, packaging, and agricultural films.
                            </p>
                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Sustainable Inks</h2>
                        </Row >

                        <Row>
                            <p className="text-dark ">
                                We use eco-friendly inks and coatings for printing and branding on our packaging. These inks are formulated with fewer volatile organic compounds (VOCs) and reduce the environmental impact of the printing process.
                            </p>
                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Aqueous Coatings</h2>
                        </Row >
                        <Row>
                            <p className="text-dark ">
                                Water-based coatings are formulated with water as the main solvent, significantly reducing the emission of volatile organic compounds (VOCs) into the environment. They offer good adhesion to various substrates, ensuring a durable and long-lasting finish.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1 ">Eco-Friendly Adhesives</h2>
                            <Row>
                                <p className="text-dark ">
                                    Adhesives are an essential part of packaging assembly. We use eco-friendly and non-toxic adhesives that are free from harmful chemicals, ensuring the safety of our products and the environment.
                                </p>
                            </Row>
                        </Row>

                    </Col>
                </Row>

            </Container>
            <Container>
                <Row>
                    <p className="text-dark">
                        The use of these diverse raw materials in the production of eco-friendly packaging highlights our commitment to sustainable practices and environmental conservation. By embracing these alternatives, we contribute to the reduction of plastic waste, the conservation of natural resources, and the promotion of a greener, more sustainable future.
                    </p>
                </Row>
            </Container>
        </section >
        <PageCarousel />
    </>
}

export default MaterialsUsed