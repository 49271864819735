import { createStore } from "redux";
import appReducers from "./reducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

//const store = createStore(appReducers);
//export default store;

const persistConfig = {
    key: 'root',
    storage,
  }
  
const persistedReducer = persistReducer(persistConfig, appReducers)

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor};