import "./footer.css";
import { DiAndroid } from "react-icons/di";
import MyAccount from "./components/my-account/my-account";
import WhyWeChoose from "./why-we-choose/why-we-choose";
import StoreInfomation from "./store-information/store-information";
import SocialMediaWidget from "./social-media-widget/social-media-widget";
import PaymentCard from "./payment-card/payment-card";
import { useState } from "react";
import NewsletterSubscribe from "./components/newsletter/newsletter-subscribe";
import { Col, Container, Row } from "react-bootstrap";


const Footer = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    return <>
        <footer className="footer-light">
            {/* <NewsletterSubscribe /> */}
            <section className="section-b-space light-layout footer-bg">
                <Container>
                    <Row className="  partition-f">
                        <Col lg={4} md={6} className={` ${isCollapsed ? 'collapsed' : ''}`}>
                            <div className="h-50 mx-3">
                                <Col className="footer-title footer-mobile-title" >
                                    {/* <h6 className="text-uppercase mb-4 footer-title1">                                  
                                  <b>about</b></h6> */}
                                    <span className="according-menu"></span>
                                </Col>

                                <Col className={`footer-contant ${isCollapsed ? 'd-none' : ''}`}>
                                    <Col className="footer-logo"><img src="../assets/images/icon/logo.png" width="260" alt="" /></Col>
                                    <p className="logo-contant mt-4">Shaping a Greener Tomorrow,<br /> One Package at a Time</p>
                                    {/* <div className="footer-title mt-4">
                                    <WhyWeChoose />
                                </div> */}

                                </Col>
                            </div>

                            <div className="h-50  d-flex align-items-center">
                                <Col className="footer-social">
                                    <Col><li><SocialMediaWidget /></li></Col>
                                </Col>
                            </div>

                        </Col>
                        <Col lg={4} sm={6} md={3} >
                            <Col className="sub-title">
                                <Col className="footer-title">
                                    <WhyWeChoose />
                                </Col>
                            </Col>
                        </Col>
                        <Col lg={4} md={12} >
                            <Col className="sub-title">
                                <Col className="footer-title">
                                    <StoreInfomation />
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Col className="sub-footer">
                <Container>
                    <Row>
                        <Col xl={6} md={6} sm={12}>
                            <Col className="footer-end">
                                <p><i className="fa fa-copyright" aria-hidden="true"></i> &copy; Biobox. All rights reserved</p>
                            </Col>
                        </Col>
                        {/* <div className="col-xl-6 col-md-6 col-sm-12">
                            <div className="payment-card-bottom">
                                <PaymentCard />
                            </div>
                        </div> */}
                    </Row>
                </Container>
            </Col>
        </footer>
    </>
}
export default Footer