import { Card, Col, Container, Image, Row } from "react-bootstrap"
import "./ethical -sourcing.css"
import Header from "../../layouts/header/header"
import PageCarousel from "../home/components/page-carousel/page-carousel"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import SEO from "../../features/seo-helmet"

const EthicalSourcing = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return <>
        <SEO title="Ethical Sourcing" description="Ethical Sourcing" keywords="Ethical Sourcing" name="Ethical Sourcing" type="Ethical Sourcing" />
        <Header />
        <section className="blog-detail-page section-b-space  ratio2_3">
            <Container className="mt-5">
                <Image className=" w-100 object-fit-cover" src="/assets/images/ethical-sourcing/former-img.jpeg"
                    height={400} />
                <Row>
                    <p className="text-dark my-4">
                        At Biobox, our commitment to sustainability extends to every aspect of our business, including ethical sourcing. We recognize the importance of responsible and ethical practices in sourcing the materials that go into our packaging solutions. Here's an overview of our ethical sourcing practices followed by our manufacturing facilities,
                    </p>
                </Row>
            </Container>


            <Container className="content-page">
                <Row className="h-100">
                    <Col lg={6} className=" mt-3">
                        <Image src="/assets/images/ethical-sourcing/ethical-sourcing.jpeg" alt="" className="w-100
                         object-fit-cover h-100 " />
                    </Col>
                    <Col className="color-bg mt-3" lg={6}>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Transparency and Accountability</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We prioritize transparency in our supply chain, ensuring that every step of the sourcing process is accountable and traceable. We work closely with suppliers who share our commitment to ethical practices.
                            </p>
                        </Row>

                        <Row className="page-title1 mt-3">
                            <h2 className="title-inner1">Sustainable Harvesting</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                In regions where our materials are sourced, we advocate for sustainable harvesting practices that protect ecosystems and minimize deforestation. We work with local communities and suppliers to promote responsible resource management.
                            </p>
                        </Row>
                        <Row className="page-title1 mt-3">
                            <h2 className="title-inner1">Fair Labor Practices</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We ensure that our suppliers adhere to fair labor practices, providing safe and ethical working conditions for their employees. This includes fair wages, reasonable working hours, and the absence of child labor.
                            </p>
                        </Row>

                        <Row className="page-title1 mt-3">
                            <h2 className="title-inner1">Compliance with Regulations</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We comply with all relevant laws and regulations related to sourcing materials, environmental protection, and labor practices. We hold ourselves accountable to the highest standards of legal and ethical conduct.

                            </p>
                        </Row>

                        <Row className="page-title1 mt-3">
                            <h2 className="title-inner1">Certification</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We actively seek and promote certification standards such as FSC (Forest Stewardship Council) and other relevant certifications that validate our commitment to ethical and sustainable sourcing.
                            </p>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <p className="text-dark mt-3">
                        Our ethical sourcing practices reflect our dedication to the well-being of people, the planet, and the communities we serve. Together, we can create a more sustainable and ethical future.
                    </p>
                </Row>
            </Container>

        </section>
        <PageCarousel />
    </>
}

export default EthicalSourcing
