import { Card, Col, Container, Row, Image } from "react-bootstrap"
import './about-us.css'
import { Fragment } from "react"
import ProductCounter from "../home/components/product-counter/product-counter"

const AboutUs = () => {

    return (<>
        <Fragment>
            <section className="blog-detail-page section-b-space ratio2_3">
                <Container>
                    <Row className="ms-2">
                        <Col className="title1">
                            <h2 className="title-inner1">About Biobox</h2>
                        </Col>
                        <Container className="bamboo-view">
                            <Row className="ms-lg-6 ">
                                <Col>
                                    <p className="p-md-3 p-2 text-dark my-0">
                                        At Biobox, we're passionate about paving the way for a sustainable future through innovative and eco-conscious packaging solutions. As a dedicated partner for environmentally friendly packaging products, we strive to redefine the industry's standards by prioritizing sustainability, functionality, and aesthetic appeal. Our journey began with a simple yet profound vision—to reduce the environmental impact of packaging materials without compromising on quality. We have been committed to crafting premium, eco-friendly packaging solutions that cater to diverse industries, ranging from food and beverage to healthcare and beyond.
                                    </p>
                                    <p className="p-md-3 p-2 text-dark my-0">
                                        Driven by a profound commitment to the planet, Our manufacturing facilities meticulously source the finest sustainable materials and employ cutting-edge manufacturing processes to create a diverse range of packaging solutions. From biodegradable and compostable materials to recyclable options, our products cater to various industries, ensuring that each solution aligns with our ethos of sustainability.                                    </p>
                                    <p className="p-md-3 p-2 text-dark">
                                        What sets us apart is our relentless pursuit of innovation. We continually explore new technologies and eco-friendly materials to offer packaging solutions that not only meet but exceed industry standards. Our team of experts works closely with clients to understand their specific needs, delivering customized packaging solutions that reflect their brand's commitment to sustainability.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
                <section className="pt-3 pb-5">
                    <ProductCounter />
                </section>
                <section className="info-sec about pb-5">
                    <Container>
                        <Row className="mt-3 ms-2">
                            <Col className="title1">
                                <h2 className="title-inner1">Our Mission</h2>
                            </Col>
                            <Container className="bamboo-view mt-3">
                                <Row className="ms-lg-6 ">
                                    <Col>
                                        <p className="p-md-3 p-2 text-dark">
                                            Biobox is driven by a clear and compelling mission: to create packaging solutions that protect the environment, conserve resources, and reduce the carbon footprint of businesses worldwide. Our commitment to sustainability is rooted in a set of core values:
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                            <Row className="mx-auto scroll-up">
                                <Col md={12} lg={4} className="my-2">
                                    <Col className="info px-lg-5 h-100 p-3 m-3">
                                        <Image src="/assets/images/icon/about-us/environmental-responsibility.png" width={120} rounded />
                                        <h3>Environmental Responsibility</h3>
                                        <p>
                                            Biobox strives to minimize the environmental impact of packaging by using renewable, recycled, and biodegradable materials. We work tirelessly to reduce waste and pollution in the supply chain.
                                        </p>
                                    </Col>
                                </Col>
                                <Col md={12} lg={4} className="my-2">
                                    <Col className="info px-lg-5 h-100 p-3 m-3">
                                        <Image src="/assets/images\icon\about-us\innovation.png" width={120} rounded />
                                        <h3>Innovation</h3>
                                        <p>
                                        Biobox places a strong emphasis on innovation, consistently exploring new technologies and materials to design packaging that is both sustainable and functional.
                                        </p>
                                    </Col>
                                </Col>
                                <Col md={12} lg={4} className="my-2">
                                    <Col className="info px-lg-5 h-100 p-3 m-3">
                                        <Image src="/assets/images/icon/about-us/collaboration.png" rounded width={120} />
                                        <h3>Collaboration</h3>
                                        <p>
                                            Biobox collaborates with clients and industry partners to foster a collective effort towards sustainability. We believe that working together is key to creating meaningful change.
                                        </p>
                                    </Col>
                                </Col>
                            </Row>
                        </Row>
                    </Container>

                </section>

                <section className="pb-5 sustainability-initiatives">

                    <Container>
                        <Row>
                            <Col className="title1">
                                <h2 className="title-inner1">Product Portfolio</h2>
                            </Col>
                            {/* <h5 className="my-3">
                                Biobox offers a diverse range of eco-friendly packaging solutions designed to meet the unique needs of various industries, including
                            </h5> */}
                        </Row>
                    </Container>
                    <Container className="portfolio-bg  mt-3">
                        <Row className="text-center">
                            <Col className="portfolio-img-container">
                                <Image src='/assets/images/icon/about-us/product-portfolio.jpeg'
                                    className="w-100 h-100" />
                            </Col>

                        </Row>
                        <Row className="info-card">
                            <Col md={6} lg={4} className="my-2 scroll-container">
                                <Col className="info h-100 scroll-up">
                                    <Image src="/assets/images/icon/about-us/biodegradable-packaging.png" width={120} rounded />
                                    <h3>Biodegradable Packaging</h3>
                                    <p>
                                        Our biodegradable packaging materials are crafted from organic, plant-based sources, ensuring that they can easily decompose without causing harm to the environment. These materials offer the same level of protection and durability as traditional packaging options while significantly reducing the carbon footprint.
                                    </p>
                                </Col>
                            </Col>

                            <Col md={6} lg={4} className="my-2">
                                <Col className="info h-100 scroll-up">
                                    <Image src="/assets/images/icon/about-us/recycled -packaging.png" width={120} rounded />
                                    <h3>Recyclable Packaging</h3>
                                    <p>
                                        Our recyclable packaging options are carefully engineered to be easily repurposed or recycled into new materials, reducing the amount of waste that ends up in landfills. By encouraging the circular economy, we strive to minimize the consumption of raw materials and energy, thereby mitigating the environmental impact of packaging production.
                                    </p>
                                </Col>
                            </Col>

                            <Col md={6} lg={4} className="my-2">
                                <Col className="info h-100 scroll-up">
                                    <Image src="/assets/images/icon/about-us/reusable.png" width={120} rounded />
                                    <h3>Reusable Packaging</h3>
                                    <p>
                                        We offer customizable and durable reusable packaging systems that can be utilized multiple times, significantly reducing the need for single-use packaging. These systems are designed to withstand the demands of various industries while maintaining their structural integrity, allowing businesses to minimize their packaging waste and overall environmental footprint.
                                    </p>
                                </Col>
                            </Col>

                            <Col md={6} lg={4} className="my-2">
                                <Col className="info  h-100 scroll-up">
                                    <Image src="/assets/images/icon/about-us/eco-friendly-printing.png" width={120} rounded />
                                    <h3>Sustainable Design</h3>
                                    <p>
                                        Our team of experts provides comprehensive sustainability consultancy services to help businesses transition to eco-friendly packaging solutions. From product design to material selection and supply chain management, we assist our clients in implementing sustainable practices that align with their business goals and environmental objectives.
                                    </p>
                                </Col>
                            </Col>

                            <Col md={6} lg={4} className="my-2">
                                <Col className="info h-100 scroll-up">
                                    <Image src="/assets/images/icon/about-us/innovative-packaging.png" width={120} rounded />
                                    <h3>Innovative Packaging</h3>
                                    <p>
                                        We continuously invest in research and development to introduce cutting-edge technologies that enhance the performance and sustainability of our packaging solutions. Our innovative approach focuses on integrating advanced materials to create packaging products that meet the highest standards of quality, functionality, and environmental responsibility.
                                    </p>
                                </Col>
                            </Col>

                            <Col md={6} lg={4} className="my-2">
                                <Col className="info h-100 scroll-up">
                                    <Image src="/assets/images/icon/about-us/compostable-packaging.png" width={120} rounded />
                                    <h3>Compostable Packaging</h3>
                                    <p>
                                        We offer a range of compostable packaging solutions that are designed to break down into natural elements when disposed of in composting conditions. Our compostable packaging not only reduces waste but also enriches the soil, providing a closed-loop solution for environmentally conscious businesses.
                                    </p>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="info-sec">
                    <Container className="about">
                        <Row>
                            <Col className="title1">
                                <h2 className="title-inner1">Sustainability Initiatives</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={4} className="my-2">
                                <Col className="info h-100 px-lg-5 p-3 m-3 scroll-up">
                                    <Image src="/assets/images/icon/about-us/zero-waste-manufacturing.png" width={120} rounded />
                                    <h3>Zero-Waste Manufacturing</h3>
                                    <p>
                                        Through the adoption of waste reduction strategies by our manufacturing facilities, including the optimization of packaging designs and material usage, we strive to minimize waste generation during the production and distribution of our packaging solutions.
                                    </p>
                                </Col>
                            </Col>
                            <Col md={12} lg={4} className="my-2">
                                <Col className="info h-100 px-lg-5 p-3 m-3 scroll-up">
                                    <Image src="/assets/images/icon/about-us/carbon-neutral-operation.png" width={120} rounded />
                                    <h3>Carbon-Neutral Operations</h3>
                                    <p>
                                        We participate in carbon offsetting programs and invest in renewable energy projects to mitigate the environmental impact of our operations. By offsetting our carbon footprint, we demonstrate our commitment to combatting climate change and preserving the planet for future generations.
                                    </p>
                                </Col>
                            </Col>
                            <Col md={12} lg={4} className="my-2">
                                <Col className="info h-100 px-lg-5 p-3 m-3 scroll-up">
                                    <Image src="/assets/images/icon/about-us/sustainable-packaging.png" width={120} rounded />
                                    <h3>Product Life Cycle Assessment</h3>
                                    <p>
                                        Our manufacturing facilities conduct a comprehensive product lifecycle analyses that enable us to identify areas where we can further enhance the sustainability of our products. By assessing the environmental impact from raw material extraction to disposal, we continuously seek opportunities to improve our processes and reduce our ecological footprint
                                    </p>
                                </Col>
                            </Col>


                            {/* <Col md={12} lg={4} className="my-2">
                                <Card className="info  px-5 m-3 h-100">
                                    <h3>Product Life Cycle Assessment</h3>
                                    <p>
                                        Biobox conducts thorough life cycle assessments of their products to continually improve their sustainability performance.
                                    </p>
                                </Card>
                            </Col> */}
                        </Row>
                        <Row className="mt-5">
                            <p>
                                Beyond promoting eco-friendly packaging, we strive to educate and raise awareness about the importance of adopting sustainable practices. Through our initiatives, we aim to inspire individuals and businesses to make informed choices that benefit both the environment and future generations.
                            </p>
                        </Row>
                        <Row>
                            <p>
                                As a company, we believe in the power of collaboration and partnerships. We collaborate with like-minded businesses, environmental organizations, and industry experts to drive positive change and promote a greener, more sustainable future.
                            </p>
                        </Row>
                        <Row>
                            <p>
                                Join us on our mission to reshape the packaging landscape. Together, let's embrace sustainable solutions that not only protect our planet but also elevate the way we think about packaging.
                            </p>
                        </Row>
                    </Container>
                </section>
            </section>
        </Fragment>
    </>)
}

export default AboutUs