import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

const Banner = () => {

    useEffect(() => {
        
    }, []);
    
    return<>
        <section className="p-0">
            <Col className="full-banner parallax  p-left product-bg">
                <Container >
                    <Row>
                        <Col md={{span:4,}}>
                            <Col className="banner-contain text-center">
                                <h3>Biobox</h3>
                                <h4>Shaping a Greener Tomorrow, One Package at a Time</h4>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </section>
    </>
}
export default Banner;
