import axios, { AxiosInstance } from "axios";
class ApiConfig{

    // private baseURL = 'http://localhost:6565/';

    // private baseURL = 'http://ecodirect.onebitconsult.com/'; 
    
    // private baseURL = 'http://www.ecopackdirect.com/';
    private baseURL = 'https://biobox.au/';

    private apiBaseUrl: string;

    constructor() {
        this.apiBaseUrl = this.baseURL + 'api/';
    }

    private getApiBaseURL = () => {
        return this.apiBaseUrl;
    }

    public getAxiosInstance = () => {
        return axios.create({baseURL: this.getApiBaseURL()});
    }

}
export default ApiConfig;
