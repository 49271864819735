
import { faCartShopping, faGear, faHeart, faMagnifyingGlass, faPhone, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../header.css"
import { useState } from "react";
import { useSelector } from "react-redux";
import CartState from "../../../products/state/cart-state";
import Menu from "./menu";
import ProductSearchBox from "../../../features/widgets/product-search/product-search-box";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";

const Toolbar = () => {
  const cartReducer = useSelector((state: CartState) => state);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate: NavigateFunction = useNavigate();

  const goToViewQuoteList = () => {
    navigate('/quotes');
  }

  return (
    <>
      <Col className="top-header d-none d-sm-block ">
        <Container>
          <Row>
            <Col className='d-none d-lg-block'>
              <Menu />
            </Col>
          </Row>
        </Container>
      </Col>
      <div className="d-block d-lg-none fixed-bottom bg-dark">
        <div className="">
          <Container className="mt-4">
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={6}>
                    <div className="header-contact">
                      <Col lg={6} className=" align-item-center ">
                        <ul className=" mb-0 flex-data">
                          {/* <li>
                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: '22px' }} />
                          </li> */}
                          {/* <li>
                            <div className="cart-qty-cls" style={{right: 'auto'}}>{cartReducer.cartReducer.cartCount}</div>
                            <FontAwesomeIcon icon={faCartShopping} style={{ fontSize: '22px' }} />
                            <ul className="dropdown-menu">
                              <li className="dropdown-item"><a href="#">Your cart is currently empty</a></li>
                            </ul>
                          </li> */}
                          <li className="onhover-div mobile-cart d-flex  p-0">
                            <div className="cart-qty-cls">{cartReducer.cartReducer.cartCount}</div>
                            <a href="javascript:void(0)" onClick={goToViewQuoteList}>
                              <div>
                                <Image src="../assets/images/icon/add-to-quote-box.png" alt="" className="media" width={25} />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </>
  );
}
export default Toolbar