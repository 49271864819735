import { Col, Container, Row, Image } from "react-bootstrap"
import "./quality-control.css"
import Header from "../../layouts/header/header"
import PageCarousel from "../home/components/page-carousel/page-carousel"
import { useEffect } from "react"
import SEO from "../../features/seo-helmet"

const QualityControl = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return <>
        <SEO title="Quality Control" description="quality control is a critical aspect of our manufacturing process"
            keywords="Ecopacking direct, quality control" name="Quality Control" type="Quality Control" />
        <Header />
        <section className="blog-detail-page section-b-space  ratio2_3">
            <Container className="mt-5">
                <Image className="w-100 object-fit-cover" src="/assets/images/quality-control/quality-control-Hero-Image.jpeg"
                    height={400} />
                <Row>
                    <p className="text-dark my-4">
                        At Biobox, quality control is a critical aspect followed by our manufacturing facility. We are committed to delivering eco-friendly packaging solutions that meet the highest standards of quality and performance.
                    </p>
                </Row>
            </Container>

            <Container className="content-page">
                <Row className="my-3 ">
                    <Col lg={6} className="text-center ">
                        <img src="/assets/images/quality-control/quality-control.jpeg"
                            className="w-100 h-100 object-fit-cover" alt="" />
                    </Col>
                    <Col className="p-3 color-bg" lg={6}>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Incoming Material Inspection</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We begin by thoroughly inspecting all incoming raw materials. Our quality control team ensures that these materials meet our strict sustainability and quality standards.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Prototyping and Design Review</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Before full-scale production, we create prototypes of the packaging to assess its design, functionality, and overall appearance. A design review is conducted to ensure that the packaging aligns with the client's requirements and our sustainability goals.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">In-Process Inspections</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                During the manufacturing process, our team conducts regular in-process inspections to monitor the quality of the packaging as it takes shape. This includes checking for proper cutting, forming, and assembly, as well as confirming that printing and decoration meet design specifications.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Quality Assurance Testing</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We perform various quality assurance tests to assess the strength, durability, and functionality of the packaging. Testing may include compression tests, drop tests, moisture resistance tests, and other relevant assessments.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Visual Inspection</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Each packaging unit is visually inspected for defects, including issues related to printing quality, adhesion, and finish.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Dimensional Accuracy</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We ensure that the packaging dimensions meet the client's specifications and that they are consistent across the production run.
                            </p>
                        </Row>


                        <Row className="page-title1">
                            <h2 className="title-inner1">Customization Verification</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                If the packaging is customized with specific branding or design elements, we ensure that these customizations are accurate and meet the client's expectations.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Final Inspection</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Before packaging the products for shipment, a final inspection is carried out to confirm that the packaging meets all quality and sustainability criteria.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Documentation and Traceability</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We maintain detailed records of quality control inspections, providing traceability for every batch of packaging produced. This documentation ensures that any issues can be quickly identified and resolved.
                            </p>
                        </Row>

                    </Col>
                    <Container>
                        <Row>
                            <p className="text-dark mt-3">
                                Our commitment to quality control is a testament to our dedication to providing customers with packaging solutions that not only protect their products but also reflect our unwavering commitment to sustainability and environmental responsibility.
                            </p>
                        </Row>
                    </Container>
                </Row>

            </Container>
        </section>
        <PageCarousel />
    </>
}

export default QualityControl