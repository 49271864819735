import { Suspense } from "react";
import { BrowserRouter as Router,Route,Routes } from "react-router-dom";
import ProductDetail from "../products/component/product-detail";
import ContactUs from "../layouts/contact-us/contact-us";
import BreadCrumb from "../layouts/category-bread-crumb/category-bread-crumb";
import ProductCheckout from "../products/component/product-checkout";
import {HomeContainer} from "../pages/home";
import AboutUs from "../pages/about-us/about-us";
import EthicalSourcing from "../pages/ethical-sourcing/ethical-sourcing";
import GivingBackTheNature from "../pages/giving-back-the-nature/giving-back-the-nature";
import MaterialsUsed from "../pages/materials-used/materials-used";
import WhyChooseUs from "../pages/why-choose-us/why-choose-us";
import Sustainability from "../pages/sustainability/sustainability";
import QualityControl from "../pages/quality-control/quality-control";
import ViewQuoteList from "../pages/quote/view-quote/view-quote-list";
import SearchProduct from "../pages/search-product/search-product";
import Composting from "../pages/composting/composting";

const AppRouter = () => {
    return <>
        <Router>
            <Routes>
                <Route path="/" element={<HomeContainer />} />
                <Route path="/quality-control" element={<QualityControl />} />
                <Route path="/ethical-sourcing" element={<EthicalSourcing />} />
                <Route path="/sustainability" element={<Sustainability />} />
                <Route path="/product-details/:productCode" element={<ProductDetail />} />
                <Route path="/contact-us" element={<ContactUs/>} />
                <Route path="/about-us" element={<AboutUs/>} />
                <Route path="/quotes" element={<ViewQuoteList />} />
                <Route path="/product-checkout" element={<ProductCheckout/>} />
                <Route path="/ethical-sourcing" element={<EthicalSourcing/>} />
                <Route path="/giving-back-the-nature" element={<GivingBackTheNature/>} />
                <Route path="/materials-used" element={<MaterialsUsed/>} />
                <Route path="/why-choose-us" element={<WhyChooseUs/>} />
                <Route path="/search-product" element={<SearchProduct />} />
                <Route path="/composting" element={<Composting />} />
            </Routes>
        </Router>
    </>
}

export default AppRouter;