import { useState, useEffect } from "react";
import ProductApiService from "../../../../data/api/services/product/product-api-service";
import ProductCard from "../../../../products/component/product-card";
import './special-product.css'
import PageSpinner from "../../../../common/spinner/inline-spinner";
import InlineSpinner from "../../../../common/spinner/inline-spinner";
import { Col, Container, Row } from "react-bootstrap";

const SpecialProduct = () => {

    const productApiService: ProductApiService = new ProductApiService();
    const [productData, setProductData] = useState<any>([]);
    const [showSpinner, setShowSpinner] = useState(false);

    const getSpecialProductsByPage = (page: number) => {
        getSpecialProducts(page);
    }

    const getSpecialProducts = async (page: number) => {
        try {
            reset();
            setShowSpinner(true);
            let result = await productApiService.getProductsByTag('special',page, 4);
            console.log("getPackageData", result);
            setProductData([...result.items]);
            setShowSpinner(false);
        } catch (error) {
            setShowSpinner(false);
            console.log("Product Error");
            console.log(error);
        }
    }

    const reset = () => {
        setProductData([]);
    }

    useEffect(() => {
        getSpecialProductsByPage(1);
    }, []);

    return <>

        <section>
            <Col className="title1">
                <h2 className="title-inner1">Special Products</h2>
            </Col>
            <section className="section-b-space pt-0 ratio_asos">
                <Container>
                    <Row>
                        <Col>
                            {<InlineSpinner variant="secondary" showSpinner={showSpinner} />}
                            {
                                productData.length > 0 ?
                                    <Row className="no-slider row">
                                        {productData.map((item: any, idx: any) => (
                                            <ProductCard product={item} />
                                        ))}

                                    </Row>

                                    : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </section>

        </section>
    </>
}
export default SpecialProduct;
