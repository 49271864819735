import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ProductCard from "../../../../products/component/product-card";
import ProductApiService from "../../../../data/api/services/product/product-api-service";
import InlineSpinner from "../../../../common/spinner/inline-spinner";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductState from "../../../../products/state/product-state";
import { clearProductAction, setProductIdAction } from "../../../../products/state/product-action";
const collectionContainer = [
    {
        img: "https://t4.ftcdn.net/jpg/06/34/96/17/240_F_634961717_2Ptow9SEJM4lN25McWJYUlj9ssPkeqfM.jpg",
        title: "CUPS AND LIDS",
        subtitle: "Lorem ipsum dolor sit consectetur adipiscing elit,",
        discription: "compostable cups are an eco-friendly alternative to conventional plastic cups. BioCup takeaway cups are made from bioplastic and bioplastic-lined premium paper certified industrially compostable to Australian Standards AS4736. Consult your local waste provider for disposal options, or visit Compost Connect. Note: only aqueous cups are suitable for home composting. Check with your local council."
    },
    {
        img: "https://www.biopak.com/media/catalog/product/b/-/b-tl-15_1.jpg?quality=85&bg-color=255,255,255&fit=bounds&height=600&width=600&canvas=600:600",
        title: "BOWLS TRAYS AND PLATES",
        subtitle: "Lorem ipsum dolor sit consectetur adipiscing elit,",
        discription: "Go green for the planet! Buy eco-friendly takeaway containers made from rapidly renewable, responsibly sourced resources like bagasse, bioplastic and FSC™ certified paperboard. Make sure to check the description to see if the takeaway food containers are home-compostable or industrially compostable. Consult your local waste provider for disposal options, or visit Compost Connect. Note: only aqueous-lined containers are suitable for home composting. Check with your local council."
    },
    {
        img: "https://as2.ftcdn.net/v2/jpg/03/43/85/43/1000_F_343854351_vxv3SZPQ1KevUZJigGol41oiwNOcNdF9.jpg",
        title: "CONTAINERS",
        subtitle: "Lorem ipsum dolor sit consectetur adipiscing elit",
        discription: "Choose compostable food trays and plates made from rapidly renewable, responsibly sourced resources like bagasse, bioplastic and FSC™ paperboard. All our eco-friendly paper meal trays are compostable and can be recycled if not contaminated with food. Consult your local waste provider for disposal options, or visit Compost Connect."
    },
    {
        img: "https://t4.ftcdn.net/jpg/01/90/11/95/240_F_190119503_yYDxi9z8CtVhbLyY8YIjS60ktnQTEm92.jpg",
        title: "BAGS AND POUCHES",
        subtitle: "Lorem ipsum dolor sit consectetur adipiscing elit,",
        discription: "disposable utensils are a sustainable alternative to plastic cutlery and plastic straws. These eco disposable utensils are made from plant-based bioplastic or FSC™ certified wood/paper. This range of takeaway utensils includes wooden cutlery and paper straws. Paper and wood items will break down in a home composting environment, while bioplastic needs to go to an industrial composting facility to break down. Talk to us about composting in your area."
    },
    {
        img: "https://t4.ftcdn.net/jpg/06/45/64/61/240_F_645646139_WhE3ZDNFcDWIhVP14AXC74GlDLEnvbGf.jpg",
        title: "CUTLERY",
        subtitle: "Lorem ipsum dolor sit consectetur adipiscing elit,",
        discription: " takeaway bags are an environmentally friendly alternative to plastic bags. Make a sustainable switch by swapping out your conventional plastic takeaway bags for these compostable and recyclable bags. These disposable bags are home-compostable and designed to biodegrade without any toxic residues, leaving the planet healthier. Talk to us about composting in your area. Custom printing is available for printed takeaway bags."
    },
    {
        img: "https://www.biopak.com/media/catalog/product/l/-/l-cdn-w_1.jpg?quality=85&bg-color=255,255,255&fit=bounds&height=360&width=360&canvas=360:360",
        title: "TOILET PAPER AND NAPKINS",
        subtitle: "Lorem ipsum dolor sit consectetur adipiscing elit,",
        discription: "We've got you covered for all your bathroom and toilet needs with our eco-friendly paper napkins and washroom supplies. Choose our compostable disposable napkins made from FSC™ certified paper and other sustainable washroom supplies. Talk to us about composting in your area."
    }
]

const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 9000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                centerMode: true,
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

const AlternateProduct = () => {

    const productApiService: ProductApiService = new ProductApiService();
    const [productData, setProductData] = useState<any>([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const productReducer = useSelector((state: ProductState) => state);
    const dispatch = useDispatch();


    const getTrendingProductsByPage = (page: number) => {
        getTrendingProducts(page);
    }

    const getTrendingProducts = async (page: number) => {
        try {
            setShowSpinner(true);
            let result = await productApiService.getAllProduct(page, 4);
            console.log("getPackageData", result);
            setProductData([...result.items]);
            setShowSpinner(false);
        } catch (error) {
            setShowSpinner(false);
            console.log("Product Error");
            console.log(error);
        }
    }

    const reset = () => {
        setProductData([]);
    }
    const getAlternateProductById = async (id: any) => {
        if(!id) {
            return;
        }
        try {
            reset();
            setShowSpinner(true);
            let data = await productApiService.getAlternateProductById(id);
            setProductData([...data]);
            console.log("getAlternateProductById", data);
            setShowSpinner(false);

        } catch (error) {
            console.log("getAlternateProductById Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getAlternateProductById(productReducer.productId.productId);
    }, [productReducer.productId.productId]);
    return <>
        {productData.length > 0 &&
            <section>
                <Col className="title1">
                    <h2 className="title-inner1">ALTERNATE PRODUCTS</h2>
                </Col>
                <section className="section-b-space pt-0 ratio_asos">
                    <Container>
                        <Row>
                            <Col>
                                {<InlineSpinner variant="secondary" showSpinner={showSpinner} />}
                                {
                                    productData.length > 0 ?
                                        <Row className="no-slider row">
                                            {productData.map((item: any, idx: any) => (
                                                <ProductCard product={item} />
                                            ))}

                                        </Row>

                                        : ''
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>

            </section>
        }
    </>
}
export default AlternateProduct;