import { Fragment, useState } from "react"
import Header from "../../layouts/header/header"
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { clearCartAction } from "../state/cart-action";
import CartApiService from "../../data/api/services/cart/cart-api-service";
import { toast } from "react-toastify";
import { Container, Form, Row, Button } from "react-bootstrap";


const ProductCheckout = () => {
    const cartApiService:CartApiService = new CartApiService();
    const appReducer = useSelector((state: any) => state);
    const [firstName, setFirstName] = useState<any | null>(null);
    const [lastName, setLastName] = useState<any | null>(null);
    const [email, setEmail] = useState<any | null>(null);
    const [contactNumber, setContactNumber] = useState<any | null>(null);
    const [companyName, setCompanyName] = useState<any | null>(null);
    const [message, setMessage] = useState<any | null>(null);
    const [location, setLocation] = useState<any | null>(null);

    const dispatch = useDispatch();
    const navigate:NavigateFunction = useNavigate();

    const onRequestQuoteListener = async (event:any) => {
        event.preventDefault();
        const payload:any = {};
        payload.products = getAllCartProducts();
        payload.firstName = firstName;
        payload.lastName = lastName;
        payload.email = email;
        payload.contactNumber = contactNumber;
        payload.companyName = companyName;
        payload.message = message;
        payload.location = location;
        try {
            await cartApiService.saveCart(payload);
            dispatch(clearCartAction());
            toast.success('Request Quote submitted Successfully !', { containerId: 'TR' ,autoClose: 1000});
            navigate('/');
        } catch(error: any) {
            const message = error.response.data.message;
            toast.error(message, { containerId: 'TR' ,autoClose: 1000});
        }
    }

    const getAllCartProducts = () => {
        const products:any[] = [];
        const cartProducts = appReducer.cartReducer.cart;
        for(let i =0;i < cartProducts.length;i++) {
            const product:any = {};
            product.productId = cartProducts[i].product.productId;
            product.qty = cartProducts[i].qty;
            products.push(product);
        }
        return products;
    }

    return <>
        <Fragment>
            <Header />
            <Container>
                <Row>
                    <Form noValidate onSubmit={onRequestQuoteListener}>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" value={firstName} onChange={event => setFirstName(event.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={lastName} onChange={event => setLastName(event.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" value={email} onChange={event => setEmail(event.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="text" value={contactNumber} onChange={event => setContactNumber(event.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" value={companyName} onChange={event => setCompanyName(event.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Location</Form.Label>
                            <Form.Select aria-label="Select Location" value={location} onChange={event => setLocation(event.target.value)}>
                                <option value="">Select</option>
                                <option value="africa">Africa</option>
                                <option value="north_america">North America</option>
                                <option value="south_america">South America</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="messageCtrl">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={event => setMessage(event.target.value)}/>
                        </Form.Group>
                        <Button className="btn btn-solid" type="submit">Request to Quote</Button>
                    </Form>
                </Row>
            </Container>
        </Fragment>
    </>
}

export default ProductCheckout