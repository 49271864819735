
export const setProductIdAction = (productId:any) => {
    const payload:any = {};
    payload.productId = productId;
    return {
        type: 'SET_PRODUCT_ID',
        payload: payload
    }
}

export const clearProductAction = () => {
    return {
        type: 'CLEAR_PRODUCT_ID',
        payload: null
    }
}