import { Col, Container, Row, Image } from "react-bootstrap";
import './why-choose-us.css';
import Header from "../../layouts/header/header";
import { Helmet } from "react-helmet";
import SEO from "../../features/seo-helmet";

const WhyChooseUs = () => {
    return <>
        <SEO title="Why we choose" description="Why we choose" keywords="Why we choose" name="" type="" />
        <Header />
        <section className="blog-detail-page section-b-space  ratio2_3">
            <Container className="mt-5">
                <Image className="w-100 object-fit-cover " src="/assets/images/why-choose-us/why-choose-us.jpeg"
                    height={400} />
                <Row>
                    <p className="text-dark mt-3">
                        At Biobox, we stand out as your trusted partner in eco-friendly packaging solutions. With a commitment to sustainability, innovation, and customer satisfaction, we are your premier choice for responsible packaging. Here's why you should choose us,
                    </p>
                </Row>
            </Container>

            <Container>
                <Row className="my-3 ">
                    <Col lg={6} className="text-center">
                        <Image src="/assets/images/why-choose-us/why-choose-us2.jpeg" className="w-100 h-100" alt="" />
                    </Col>
                    <Col className="p-3 color-bg" lg={6}>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Unparalleled Eco-Consciousness:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We breathe sustainability in every aspect of our business. Our core mission is to provide packaging that protects both your products and the environment. From sourcing eco-friendly materials to implementing waste-reduction strategies, we are deeply committed to minimizing our ecological footprint.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Extensive Eco-Friendly Product Range:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Choose from our diverse range of eco-friendly packaging solutions. Whether you need biodegradable packaging, recycled materials, or innovative designs that optimize material usage, we offer a comprehensive selection to meet your unique needs.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Customization and Brand Enhancement:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We understand that your brand identity matters. That's why we offer customization options to ensure your packaging not only reflects your values but also enhances your brand's visibility and appeal.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Innovation and Sustainability Go Hand in Hand:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Our team of packaging experts is constantly exploring new materials and technologies to push the boundaries of sustainability. We are at the forefront of eco-packaging innovation, ensuring you get access to the latest and most eco-friendly options.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Seamless Collaboration:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Your success is our priority. We believe in open communication and collaborative partnerships. We work closely with you to understand your packaging needs, offering tailored solutions that align with your goals.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Environmental Stewardship:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We practice what we preach. Our operations are designed to minimize waste, reduce energy consumption, and promote sustainability at every level. We are dedicated to making a positive impact on the environment.
                            </p>
                        </Row>
                        <Row className="page-title1">
                            <h2 className="title-inner1">Competitive Pricing:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                Eco-friendly packaging doesn't have to break the bank. We offer competitive pricing to make responsible packaging choices accessible to businesses of all sizes.
                            </p>
                        </Row>

                        <Row className="page-title1">
                            <h2 className="title-inner1">Responsiveness and Reliability:</h2>
                        </Row>
                        <Row>
                            <p className="text-dark ">
                                We value your time and your trust. Count on Biobox for responsive customer support, reliable delivery, and packaging solutions that exceed your expectations.
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Container>



            <Container>
                <Row>
                    <p className="text-dark ">
                        Your satisfaction and success are our ultimate goals. We take pride in helping you achieve your packaging objectives while making a positive impact on the environment.
                    </p>
                    <p className="text-dark ">
                        Make the responsible choice today. Choose Biobox for eco-friendly packaging solutions that protect your products and the planet. Contact us now to discuss your packaging needs and let's embark on a journey towards a more sustainable future together.
                    </p>
                </Row>
            </Container>



        </section>
    </>
}

export default WhyChooseUs
