import '../component/box/box.css';
import { useEffect, useState } from "react";
import { Col, Container, Form, Image, Row, Tab, Tabs } from "react-bootstrap";
import ProductApiService from "../../data/api/services/product/product-api-service";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCartAction } from "../state/cart-action";
import { toast } from "react-toastify";
import { QuantityWidget } from "../../features/widgets";
import DropDown from "../../features/widgets/drop-down/drop-down";
import Specification from "./specification/specification";
import Box from "./box/box";
import Header from "../../layouts/header/header";
import ImageMagnify from 'react-image-magnify';
import AlternateProduct from "../../pages/home/components/alternate-products/alternate-products";
import RelatedProduct from "../../pages/home/components/related-products/related-products";
import NewsletterSubscribe from '../../layouts/footer/components/newsletter/newsletter-subscribe';
import { clearProductAction, setProductIdAction } from '../state/product-action';
import ProductState from '../state/product-state';
import ReactImageMagnify from 'react-image-magnify';
import CategoryBreadCrumb from '../../layouts/category-bread-crumb/category-bread-crumb';
import { Helmet } from 'react-helmet';
import SEO from '../../features/seo-helmet';
let data: any = {};
const ProductDetail = () => {

    const productApiService = new ProductApiService();
    const { state } = useLocation();
    const productReducer = useSelector((state: ProductState) => state);
    const { productCode } = useParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const [product, setProduct] = useState<any>({});
    const [variantData, setVariantData] = useState<any>([]);
    const [galleryImgData, setGalleryImgData] = useState<any>([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const [imageProps, setImageProps] = useState<any>({ smallImage: {}, largeImage: {}, enlargedImageContainerStyle: { background: '#fff', zIndex: 9 } });
    const navigate: NavigateFunction = useNavigate();
    const [categoryId, setCategoryId] = useState('');

    let qtyOrdered: Number;
    const dispatch = useDispatch();

    const reset = () => {
        setProduct({});
        setVariantData([]);
        setGalleryImgData([]);
    }

    const getProductByCode = async (productCode: any) => {
        try {
            reset();
            if(productCode){
                productCode = encodeURIComponent(productCode);
              }
            let data:any = await productApiService.getProductByCode(productCode);
            dispatch(clearProductAction());
            dispatch(setProductIdAction(data.productId));
            setProduct({ ...data });
            updateProductState(data);
            highlightImage(data.svgImgUrl);
            await loadProductVariants(data);
            setGalleryImgData([...data.galleries]);
            console.log("getProductById", data);
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    const updateProductState = (product: any) => {
        if (product) {
            setCategoryId(product.categories[0].category_code);
        }
    }

    const loadProductVariants = async (product: any) => {
        if (!product.baseProduct) {
            return;
        }
        const baseProductDetails: any = await productApiService.getBaseProductById(product.baseProduct.id);
        const variants: any[] = baseProductDetails.baseProductVariants;
        highlightSelectedOption(variants, product.variants);
        console.log("variants", variants);
        setVariantData([...variants]);
    }

    const highlightSelectedOption = async (variants: any[], selectedProductVariants: any[]) => {
        for (let i = 0; i < variants.length; i++) {
            for (let j = 0; j < selectedProductVariants.length; j++) {
                if (variants[i].variant.id == selectedProductVariants[j].variant.id) {
                    setSelectedVariantOption(variants[i].options, selectedProductVariants[j].option);
                }
            }
        }
    }

    const setSelectedVariantOption = (variantOptions: any[], productOption: any) => {
        for (let i = 0; i < variantOptions.length; i++) {
            if (variantOptions[i].option.id == productOption.id) {
                variantOptions[i].selected = true;
                variantOptions[i].className = 'highlight-option';
            } else {
                variantOptions[i].selected = false;
                variantOptions[i].className = '';
            }
        }
    }

    const getProductByVariants = async () => {
        try {
            const variants: any[] = [];
            for (let i = 0; i < variantData.length; i++) {
                const variant: any = {};
                variant.variantId = variantData[i].variant.id;
                for (let j = 0; j < variantData[i].options.length; j++) {
                    if (variantData[i].options[j].selected) {
                        variant.optionId = variantData[i].options[j].option.id;
                        break;
                    }
                }
                variants.push(variant);
            }
            let data = await productApiService.getProductByVariants(product.productId, variants);
            console.log("getProductByFilter", data);
            return data;
        } catch (error) {
            console.log("getProductByFilter Error");
            console.log(error);
        }
    }

    const addItemToCart = () => {
        let payload: any = {}
        payload.product = product;
        payload.qty = qtyOrdered;
        if (!payload.qty) {
            toast.error('Quantity cannot be Empty! ', { containerId: 'TR', autoClose: 1000 });
            return;
        }
        dispatch(addCartAction(payload));
        toast.success('Product added Successfully!', { containerId: 'TR', autoClose: 1000 });
        navigate('/quotes');
    }

    const onQuantityChanged = (qty: Number) => {
        console.log(qty);
        qtyOrdered = qty;
    }

    const highlightImage = (svgImgUrl: string) => {
        const imageProps = {
            smallImage: {
                alt: product.name,
                isFluidWidth: true,
                src: svgImgUrl,
            },
            largeImage: {
                src: svgImgUrl,
                width: 1200,
                height: 1800,
            },
            enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
        };

        setImageProps({ ...imageProps });
    }

    const setVariantOptionSelected = async (variant: any, variantIdx: number, item: any) => {
        for (let i = 0; i < variant.options.length; i++) {
            if (item.option.id == variant.options[i].option.id) {
                variant.options[i].selected = true;
            } else {
                variant.options[i].selected = false;
            }
        }
        variantData[variantIdx] = variant;
        setVariantData([...variantData]);
    }

    const highlightSelectedVariants = async (variant: any, variantIdx: number, item: any, product: any) => {
        for (let i = 0; i < variant.options.length; i++) {
            if (variant.options[i].selected
                && item.option.id == variant.options[i].option.id) {
                variant.options[i].className = (product) ? 'highlight-option' : 'highlight-nonselect-option';
            } else {
                variant.options[i].className = '';
            }
        }
        variantData[variantIdx] = variant;
        highlightRemainingVariant(variantData, variant, product);
        setVariantData([...variantData]);
    }


    const highlightRemainingVariant = async (variantData: any, aVariant: any, product: any) => {
        if (!product) {
            return;
        }
        for (let j = 0; j < variantData.length; j++) {
            const variant = variantData[j];
            if (variant.id == aVariant.id) {
                continue;
            }
            for (let i = 0; i < variant.options.length; i++) {
                if (variant.options[i].selected && variant.options[i].className == 'highlight-nonselect-option') {
                    variant.options[i].className = 'highlight-option';
                }
            }
        }
    }

    const onItemSelected = async (variant: any, variantIdx: number, item: any) => {
        setVariantOptionSelected(variant, variantIdx, item);
        let data: any = await getProductByVariants();
        highlightSelectedVariants(variant, variantIdx, item, data.data);
        if (data.data) {
            setProduct({ ...data.data });
            highlightImage(data.data.svgImgUrl);
            dispatch(clearProductAction());
            dispatch(setProductIdAction(data.data.productId));
            setGalleryImgData([...data.data.galleries]);
            updateProductState(data.data);
        }
    }
    const showGalleryImageInContainer = (item: any) => {
        highlightImage(item.imageURL);
        setSelectedItem(item);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getProductByCode(productCode);
    }, [productCode]);

    useEffect(() => {
        if (galleryImgData.length > 0) {
            setSelectedItem(galleryImgData[0]);
        }
    }, [galleryImgData]);

    return <>
        <SEO title={product.seoTitle} description={product.seoDescription} keywords={product.seoKeywords} name="" type="" />
        <Header />
        <section className="product-detail-section section-b-space">
            <Container className="mt-5">
                <Col>
                    <CategoryBreadCrumb categoryId={categoryId} />
                    <Row>
                        <Col md={6} className="d-flex align-items-center">
                            <Row>
                                <Col md={8} className="mx-auto">
                                    <Container className="image-view">
                                        <Row>
                                            <Col className="h-25">
                                                <ReactImageMagnify smallImage={imageProps.smallImage} largeImage={imageProps.largeImage}
                                                    enlargedImageContainerStyle={imageProps.enlargedImageContainerStyle} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md={12} className="mx-auto">
                                    <Container>
                                        <Row>
                                            {galleryImgData.length > 0 &&
                                                galleryImgData.map((item: any) => (
                                                    <Col xs={6} md={2} key={item.id}>
                                                        <a
                                                            href='javascript:void(0)'
                                                            onClick={() => showGalleryImageInContainer(item)}
                                                            className={selectedItem === item ? 'selected' : ''}
                                                        >
                                                            <Image src={item.imageURL} thumbnail fluid className="gallery-image mt-4" style={{ width: '100%', height: '100%' }} />
                                                        </a>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Container>
                                </Col>

                            </Row>
                        </Col>
                        <Col md={6} className="rtl-text product-ps product-dtl">
                            <Col className="product-right">
                                <Container>
                                    <Row><h1> {product.name} </h1></Row>
                                    <Row>
                                        <Container className="my-3">
                                            <Row>
                                                {
                                                    variantData.map((variant: any, idx: any) => (
                                                        <Row>
                                                            <Row><label className="fw-bold">{variant.variant.variantName}</label></Row>
                                                            <Col sm={6} md={12}>
                                                                <Row>
                                                                    {variant.options.map((variantOption: any) => (
                                                                        <Col sm={3} className={variantOption.className + " radio-toolbar box-b mb-3 mx-2"} onClick={(e) => {
                                                                            e.preventDefault(); onItemSelected(variant, idx, variantOption);
                                                                        }}>
                                                                            <input type="radio" id="m_0" value={variantOption.option.id} />
                                                                            <label htmlFor="m_0" className='mb-0'>{variantOption.option.option}</label>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </Row>
                                        </Container>
                                    </Row>
                                    <Row>
                                        <Container className="my-2">
                                            <Row>
                                                <Col>
                                                    <label className=" mt-2 fw-bold me-3">MOQ</label>
                                                    <label className="mx-5 mt-2"><span>{product.moq}</span></label>
                                                </Col>
                                                {/* <Col sm={2}></Col> */}
                                            </Row>
                                        </Container>
                                    </Row>
                                    <Row className="my-2">
                                        <Col md={8}>
                                            <QuantityWidget moq={product.moq} onValueChanged={onQuantityChanged}></QuantityWidget>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="product-buttons mt-2">
                                            <a className="btn btn-solid w-50 ms-0" onClick={addItemToCart}>add to Quote Box</a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Col>
                    </Row>
                    {/* <Row className="mx-auto">
                        <Col md={6} className="d-flex align-items-center">
                            <Col md={8} className="mx-auto">
                                <Container className="image-view">
                                    <Row>
                                        
                                    </Row>
                                </Container>
                            </Col>
                        </Col>
                    </Row> */}
                    <Row className='py-5 product-description'>
                        <Row className='my-2'>
                            <Col className='page-title1 text-start'>
                                <h2 className="title-inner1">Description</h2>
                            </Col>
                        </Row>
                        <Row>
                            <p> {product.description} </p>
                        </Row>
                        <Row className='mt-5 mb-2'>
                            <Col className='page-title1 text-start'>
                                <h2 className="title-inner1">Product Details</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Specification product={product} />
                        </Row>
                    </Row>
                </Col>
            </Container>
            <AlternateProduct />
            <RelatedProduct />
        </section>
        <NewsletterSubscribe />
    </>
}
export default ProductDetail;

