import HttpClientWrapper from "../../http-client-wrapper";

class ContactUsApiService{

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    saveContactUs = async(payload:any):Promise<void> => {
        console.log("ContactUsApiService saveContactUs() START");   
        await this.httpClientWrapper.post('/v1/contactUs',payload);
        console.log("ContactUsApiService saveContactUs() END");     
    }


}
 export default ContactUsApiService