export const saveCategoryListAction = (categories:any[]) => {
    return {
        type: 'SAVE_CATEGORY_LIST',
        payload: categories
    }
}

export const clearCategoryListAction = () => {
    return {
        type: 'CLEAR_CATEGORY',
        payload: null
    }
}
